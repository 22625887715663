import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useBookingHeaderStyle = makeStyles((theme: Theme) => createStyles({
    root: {
      minHeight: 335,
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    typographyH1: {
      color: theme.palette.common.white,
      maxWidth: 1024,
      padding: theme.spacing(0, 2),
      margin: 'auto',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    bookingHeader: {
      backgroundSize: 'cover',
    },
  }),
)

import React from 'react'
import { Breadcrumb, BreadcrumbStep } from '@dsk-lib/reservation'
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router'
import Footer from '../../components/Footer'
import { useSelector, useDispatch } from 'react-redux'
import Payment from './Payment'
import Account from './Account'
import Recap from './Recap'
import { Box } from '@material-ui/core'
import Container from './components/Container'
import Confirmation from './Confirmation'
import Search from './Search'
import Resume from './Resume'
import { setReservationCurrentStep } from '../../store/reservation/actions'

export const stepList: IStep[] = [
  {
    name: 'Réservation',
    slug: 'recherche',
  },
  {
    name: 'Récapitulatif',
    slug: 'recapitulatif',
  },
  {
    name: 'Compte',
    slug: 'compte',
  },
  {
    name: 'Paiement',
    slug: 'paiement',
  },
  {
    name: 'Confirmation',
    slug: 'confirmation',
  },
]

interface IStep {
  name: string,
  slug: string,
}

interface IReservationProps extends RouteComponentProps {
}

const getCurrentStepFromUrlPathName = (): number => {
  if (window.location.pathname.indexOf('/reservation/resume') === 0) {
    return 5
  }
  const index = stepList.findIndex((step) => window.location.pathname.indexOf(`/reservation/${step.slug}`) === 0)

  return index > -1 ? index : 0
}

export default ({history}: IReservationProps) => {
  const reservation = useSelector((state: any) => state.reservation)
  const dispatch = useDispatch()
  const currentStep = getCurrentStepFromUrlPathName()

  const handleStepperClick = (id: number) => {
    return () => {
      const step = stepList[id]
      dispatch(setReservationCurrentStep(id))
      history.push(step.slug)
    }
  }

  return (
    <>
      <Box style={{backgroundColor: '#f3f3f8', paddingTop: 30}}>
        {
          currentStep < 5 && <Breadcrumb>
            {stepList.map((step, id) => {
              return (
                <BreadcrumbStep
                  key={id}
                  onClick={handleStepperClick(id)}
                  active={id <= reservation.currentStep}
                  current={id === reservation.currentStep}
                  id={id + 1}
                  name={step.name}
                />
              )
            })}
          </Breadcrumb>
        }
        <Box style={{position: 'relative', zIndex: 1, paddingTop: 50}}>
          <Container>
            <Switch>
              <Route path="/reservation/paiement" component={Payment}/>
              <Route path="/reservation/compte" component={Account}/>
              <Route path="/reservation/recapitulatif" component={Recap}/>
              <Route path="/reservation/confirmation" component={Confirmation}/>
              <Route path="/reservation/recherche" component={Search}/>
              <Route path="/reservation/resume" component={Resume}/>
              <Redirect from="/" to="/reservation/recherche"/>
            </Switch>
          </Container>
        </Box>
      </Box>
      <Footer wave={window.location.pathname !== '/reservation/recherche'} style={{marginTop: 30}}/>
    </>
  )
};

// React
import React from "react";

// Router
import {
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

// dsk-lib
import MenuGalaxy from "menu-galaxy";

// Pages
import WithPagehead from "./pages/Layout/WithPagehead";
import Reservation from "./pages/Reservation";

// Components
import Modal from "./components/Modal";

// Utils
import { getLinkUrlParams } from "./utils/Auth";

// dsk-lib
import { useUserAuthentication } from "@dsk-lib/user";

// Config
import { MENU_GALAXY } from "./config/app.settings";

// Store
import { resetNotification } from "./store/notification/actions";
import { useDispatch } from "react-redux";

// Component
import Notification from "./components/Notification/Notification";

// Styles
import "./assets/styles/menu-galaxy.scss";

// import { useAppStyle } from './app.styles'

import {
  ROUTE_BOOKING,
  ROUTE_BOOKING_STEP,
  LoggedRouteList, ROUTE_LANDING,
} from "./config/route.constant";

// Page
import BookingPage from "./pages/BookingPage";
import { CircularProgress, Box } from "@material-ui/core";
import { setReservationCurrentStep } from "./store/reservation/actions";
import LandingPage from "./pages/LandingPage/LandingPage";

/**
 * App properties
 */
interface IAppProps extends RouteComponentProps<any> {}

/**
 * App component
 * @param location
 */
export default ({ location }: IAppProps) => {
  /** State */
  const [modalOpen, setModalOpen] = React.useState(false);
  /** Authentication hook */
  const { authentication, keycloak } = useUserAuthentication();
  /** Store */
  const dispatch = useDispatch();
  const history = useHistory();
  const routeIsLock = useRouteMatch(LoggedRouteList);
  const isPageWithAuthentication = useRouteMatch([ROUTE_LANDING, '/'])

  /**
   * Close notification
   */
  const closeNotification = () => {
    dispatch(resetNotification());
  };

  // Styles
  // const classes = useAppStyle()
  // const BreadcrumbsRouterProps = {className: classes.breadcrumbs}
  // const BreadcrumbsTypographyProps = {variant: 'subtitle2' as 'subtitle2', className: classes.typography}
  /** Authentication quiz effect lock */
  React.useEffect(() => {
    if (/^\/quiz/.test(location.pathname)) {
      setModalOpen(!authentication.isAuthenticated);
    }
  }, [location.pathname, authentication.isAuthenticated]);

  React.useEffect(() => {
    if (
      !authentication.isPending &&
      !authentication.isAuthenticated &&
      routeIsLock &&
      routeIsLock.isExact
    ) {
      history.push("/reservation/recherche");
      dispatch(setReservationCurrentStep(0));
    }
  }, [authentication, dispatch, history, routeIsLock]);

  return (
    <>
      <Modal
        title="Connectez-vous"
        open={modalOpen}
        buttonOne="Je me connecte"
        buttonOneTo="/login"
        buttonTwo="Je m'inscris"
        buttonTwoTo="/register"
      >
        <p>
          Pour effectuer ce test, vous devez d’abord vous inscrire ou vous
          connecter.
          <br />
          Ça ne prend que quelques secondes.
        </p>
      </Modal>
      <MenuGalaxy
        isLogged={authentication.isAuthenticated}
        service="clea"
        config={{ url: MENU_GALAXY }}
        getLinkUrlParams={url => getLinkUrlParams(url, keycloak)}
      />
      {authentication.isPending && !isPageWithAuthentication ? (
        <Box
          justifyContent="center"
          pt={10}
          pb={10}
          ml="auto"
          mr="auto"
          width={40}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Switch>
          <Route exact={true} path={ROUTE_LANDING} component={LandingPage} />
          <Route exact={true} path={ROUTE_BOOKING} component={BookingPage} />
          <Route
            exact={false}
            path={ROUTE_BOOKING_STEP}
            component={Reservation}
          />
          <Route exact={false} path="/" component={WithPagehead} />
        </Switch>
      )}

      <Notification onClose={closeNotification} />
    </>
  );
};

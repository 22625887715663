import { clearUserData } from '../store/reservation'
import { ROUTE_PROFILE } from '../config/route.constant';

export const getLinkUrlParams = (
  linkUrl: string, keycloak?: any
): { action: string | null } => {
  if (keycloak) {
    switch (linkUrl) {
      case "/login":
        keycloak.login();
        return { action: "callback" };
      case "/register":
        keycloak.register();
        return { action: "callback" };
      case "/logout":
        keycloak.logout();
        clearUserData();
        return { action: "callback" };
      case "/profil":
        window.location.href = `${ROUTE_PROFILE}?redirectUrl=${window.location.href}`;
        return { action: "callback" };
      default:
        return { action: null };
    }
  } else {
    return { action: null };
  }
};

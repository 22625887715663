// React
import React from 'react'

// Lib
import { SearchBar } from '@dsk-lib/reservation'
import { Box, Typography, Paper, Grid } from '@material-ui/core'

// Component
import BookingHeader from './BookingHeader'
import Skills from '../../components/Skills'
import { SkillsContainer } from '../../components/Skills'
import Footer from '../../components/Footer'

// Store
import { setReservationSearch } from '../../store/reservation/actions'
import { useDispatch, useSelector } from 'react-redux'

// Constant
import { HEADER_TITLE } from './bookingHeader.constant'

// Dsk-lib
import { EchoTitle } from '@dsk-lib/reservation'
import { IFeature } from '@dsk-lib/reservation/dist/services/geocode'

// Styles
import { useBookingPageStyle } from './bookingPage.style'
import { useBookingHeaderStyle } from './bookingHeader.style'

// Router
import { RouteComponentProps } from 'react-router'

// Assets
import illust1 from './assets/img/ic-reservation.svg'
import illust2 from './assets/img/ic-prix.svg'
import illust3 from './assets/img/ic-dates.svg'
import imgResa from './assets/img/img-web-resa.png'
import imgResa2 from './assets/img/img-web-resa@2x.png'
import imgResa3 from './assets/img/img-web-resa@3x.png'
import icPlaceholder from './assets/img/ic-placeholder.svg'
import icCursor from './assets/img/ic-cursor.svg'
import icChecked from './assets/img/ic-checked.svg'
import icTrophy from './assets/img/ic-trophy.svg'
import bookingHeaderBg from './assets/img/booking-header-bg.png'
import bookingHeaderBgPlaceholder from './assets/img/booking-header-bg@0x.png'
import { IAppState } from '../../store'
import { MIN_DATE } from '../../config/app.settings'

/**
 * Booking page component
 */
const BookingPage = ({history}: RouteComponentProps) => {
  /** Styles */
  const classes = useBookingPageStyle()
  const headerClasses = useBookingHeaderStyle()
  const {search} = useSelector((state: IAppState) => state.reservation)

  /** Store */
  const dispatch = useDispatch()

  const handleSearchSubmit = (location: IFeature, from: Date, to: Date ) => {
    dispatch(setReservationSearch({location, from, to}))
    history.push('/reservation/recherche') // TODO fix url
  }

  return (
    <div>
        <BookingHeader headerBgUrl={bookingHeaderBg} headerBgPlaceholderUrl={bookingHeaderBgPlaceholder}>
          <Typography className={headerClasses.typographyH1} variant="h1">
            {HEADER_TITLE}<sup><i className={classes.icLaPoste}/></sup>
          </Typography>
        </BookingHeader>

      <div className={classes.container}>
        <Box className={classes.searchBarWrapper}>
          <SearchBar black={true} initialValues={search} onSearch={handleSearchSubmit} minDate={MIN_DATE}/>
        </Box>

        <Box className={classes.illustrationsWrapper}>
          <SkillsContainer>
            <Skills image={illust1} alt="identifier" height={63}>
              <Typography variant="h6" className={classes.skillTitle} align="center"> Réservation en ligne</Typography>
              <Typography className={classes.illustrationParagraph}  align="center">
                De chez vous, réservez votre examen dès que vous êtes prêt
              </Typography>
            </Skills>
            <Skills image={illust2} alt="identifier" height={63}>
              <Typography variant="h6" className={classes.skillTitle} align="center" >CléA Numérique</Typography>
              <Typography className={classes.illustrationParagraph} align="center">
                Une certification reconnue délivrée par Certif’ Pro
              </Typography>
            </Skills>
            <Skills image={illust3} alt="identifier" height={63} >
              <Typography variant="h6" className={classes.skillTitle}  align="center">Des dates chaque jour</Typography>
              <Typography className={classes.illustrationParagraph}  align="center">
                Des sessions d'examen chaque jour dans toute la France
              </Typography>
            </Skills>
          </SkillsContainer>
        </Box>
      </div>

      <Box className={classes.explanationWrapper}>
        <div className={classes.container}>
          <EchoTitle echo="Comment ça marche ?" underline={true}>Comment ça marche ?</EchoTitle>
          <img className={classes.explanationIllus} src={imgResa}
               srcSet={`${imgResa} 1x, ${imgResa2} 2x, ${imgResa3} 3x`} alt=""/>
        </div>

        <Grid className={classes.paperWrapper} container={true} direction="row" justify="space-between" >
          <Grid item={true} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <img className={classes.paperImage} src={icPlaceholder} alt=""/>
              <Typography variant="h6" align="center" color="primary"
                          className={classes.paperTitle}>Choisissez</Typography>
              <Typography variant="h5" align="center" className={classes.paperDescription}>Choisissez le centre le plus
                proche de chez vous</Typography>
            </Paper>
          </Grid>
          <Grid item={true} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <img className={classes.paperImage} src={icCursor} alt=""/>
              <Typography variant="h6" align="center" color="primary"
                          className={classes.paperTitle}>Choisissez</Typography>
              <Typography variant="h5" align="center" className={classes.paperDescription}>Sélectionnez la session à la
                date et l'heure qui vous conviennent</Typography>
            </Paper>
          </Grid>
          <Grid item={true} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <img className={classes.paperImage} src={icChecked} alt=""/>
              <Typography variant="h6" align="center" color="primary"
                          className={classes.paperTitle}>Réservez</Typography>
              <Typography variant="h5" align="center" className={classes.paperDescription}>Réservez et réglez votre session en ligne</Typography>
            </Paper>
          </Grid>
          <Grid item={true} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <img className={classes.paperImage} src={icTrophy} alt=""/>
              <Typography variant="h5" align="center" color="primary" className={classes.paperTitle}>Obtenez
                ;)</Typography>
              <Typography variant="h6" align="center" className={classes.paperDescription}>Tout est confirmé ?
                Rendez-vous dans votre centre La Poste pour le jour j !</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  )

}

export default BookingPage

// Types
import { INotification, NotificationActionTypes, NOTIFICATION_ACTION_TYPES } from './type'

/**
 * Set notification action
 * @param notification - notification content
 */
export const setNotification = (notification: INotification): NotificationActionTypes => (
  {type: NOTIFICATION_ACTION_TYPES.SET_NOTIFICATION, notification}
)

/**
 * Reset notification action
 */
export const resetNotification = (): NotificationActionTypes => (
  {type: NOTIFICATION_ACTION_TYPES.RESET_NOTIFICATION}
)

import * as React from "react";
import styled from "styled-components";
import { Icon, Button, Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getQuizStatus, getUserBilan } from "../../utils/QuizStatus";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -10px;

  @media screen and (max-width: 940px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const CardHead = styled.div`
  height: 343px;
  max-width: 560px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 156px;
  }
  p {
    padding: 0 32px;
    height: 56px;
    margin-top: 42px;
    font-size: 24px;
    text-align: center;
    span.type {
      color: #75b519;
    }
  }
`;

interface ICardFooterProps {
  done?: boolean;
}
const CardFooter = styled.div`
  max-width: 560px;
  width: 100%;
  padding: 0 53px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props: ICardFooterProps) => (!props.done ? "128px" : "235px")};

  p {
    font-size: 14px;
    text-align: center;
    .score {
      font-size: 20px;
      color: #75b519;
      &.failed {
        color: #f0351d;
      }
    }
  }
`;

const IconCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid #75b519 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export interface ICardProps {
  id: string,
  score?: string;
  finished?: boolean;
  done?: boolean;
  pursue?: boolean;
  image: string;
  title: string;
  to: string;
  onRetry?: () => void;
}

export default ({
  image,
  title,
  finished,
  score,
  pursue,
  done,
  to,
  id,
  onRetry,
}: ICardProps) => {
  const retry = () => {
    if (onRetry) {
      onRetry();
    }
  };
  getUserBilan('11119')
  return (
    <Card elevation={4} style={{ marginTop: 32, width: 560, paddingTop: 32 }}>
      <CardHead>
        <img src={image} alt={title} />
        <p>
          Quel utilisateur êtes-vous ?
        </p>
      </CardHead>
      <CardFooter done={done}>
        {done && finished && (
          <IconCircle>
            <Icon color="primary">check</Icon>
          </IconCircle>
        )}
        {done && score && (
          <p>
            <span className="score">{getUserBilan(id).name}</span>
          </p>
        )}
        <Link to={to} style={{ textDecoration: "none" }}>
          <Button
            variant={!done ? "contained" : "outlined"}
            color="primary"
            style={{ width: 240 }}
            onClick={retry}
          >
            {!done
              ? pursue
                ? "Continuer le test"
                : "Faire le test"
              : "Refaire le test"}
          </Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

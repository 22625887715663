import * as React from "react";
import styled from "styled-components";

interface IContainerProps {
  width?: number | string;
  height?: number | string;
}

const Container = styled.div`
  width: ${(props: IContainerProps) => props.width};
  height: ${(props: IContainerProps) => props.height};
  position: absolute;
  bottom: 70px;
  z-index: 0;
`;

interface IBackgroundProps {
  width?: number | string;
  height?: number | string;
}

export default ({ width, height }: IBackgroundProps) => {
  width = width || "100vw";
  height = height || 463;
  return (
    <Container width-="100%">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        width={width}
        height={height}
        viewBox="0 0 1440 463"
      >
        <defs>
          <linearGradient
            id="a"
            x1="0%"
            x2="102.392%"
            y1="55.169%"
            y2="45.788%"
          >
            <stop offset="0%" stopColor="#9ED604" />
            <stop offset="100%" stopColor="#28BC6D" />
          </linearGradient>
        </defs>
        <path
          fill="url(#a)"
          d="M1440 342s-81 30.5-184.5 15S1002 273 844 252s-493 78-556 99.5S0 462.83 0 463V0h1440v342z"
          transform="rotate(-180 720 231.5)"
        />
      </svg>
    </Container>
  );
};

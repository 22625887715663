import React from 'react'
import { ConfirmationCard } from '@dsk-lib/reservation'
import { useSelector, useDispatch } from 'react-redux'
import { setReservationCurrentStep } from '../../store/reservation/actions'
import { Redirect } from 'react-router'
import { IAppState } from '../../store'
import styled from 'styled-components'

export default () => {

  const {site, session, reservationId} = useSelector((state: IAppState) => state.reservation)
  const dispatch = useDispatch()

  if(!site || !session) {
    dispatch(setReservationCurrentStep(0))
    return <Redirect to="/reservation/recherche" />
  }

  const place = {
    zipcode: +site.zipcode,
    name: site.name,
    address: site.address,
    city: site.city,
    date: new Date(session.openingTime),
    reservationId: reservationId || ''
  }

  const Container = styled.div`
    max-width: 788;
    width: 100%;
    p {
      white-space: pre-wrap;
      text-align: center;
    }
  `

  return (
    <>
      <Container>
        <ConfirmationCard className="test" title={'Votre réservation a bien été effectuée \n Vous allez recevoir un email de convocation avec toutes les informations sur la session de certification.'} {...place}/>
      </Container>
    </>
  )
};

import React from 'react'
import { ConfirmationCard } from '@dsk-lib/reservation'
import { Box, Link } from '@material-ui/core'
import Footer from '../../components/Footer'

const placeMock = {
  name: 'École de la banque et du réseau Villeurbanne',
  address: '1 rue Hypolyte Tonkin',
  zipcode: 69100,
  city: 'Villeurbanne',
  date: new Date(),
  price: '25,23',
  reservationId: '1259611919',
}

export default () => {
  return (
    <>
      <Box style={{maxWidth: 788, width: '100%'}}>
        <ConfirmationCard title={'Votre date d’examen est fixée :'} {...placeMock}>
          <Box mt={2}>
            <Link color="textSecondary">Annuler ma réservation</Link>
          </Box>
        </ConfirmationCard>
      </Box>
      <Footer wave={true} style={{marginTop: 30}}/>
    </>
  )
};

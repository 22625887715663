// Type
import { NotificationActionTypes, INotification, NOTIFICATION_ACTION_TYPES } from './type'

/**
 * Notification state properties
 */
export interface INotificationState {
  /** Current notification */
  notification?: INotification
}

/**
 * Notification initial state
 */
const initialState = {}

/**
 * Notification reducer
 * @param state - notification current state
 * @param action - notification action
 */
const notificationReducer =
  (state: INotificationState = initialState, action: NotificationActionTypes): INotificationState => {
  switch (action.type) {
    case NOTIFICATION_ACTION_TYPES.RESET_NOTIFICATION:
      return {}
    case NOTIFICATION_ACTION_TYPES.SET_NOTIFICATION:
      return {...state, notification: action.notification}
    default:
      return state
  }
}

export default notificationReducer

// Material ui
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";

// Assets
import DeviceQuizImg from "./assets/device-quiz.png";
import DeviceQuizImg2x from "./assets/device-quiz@2x.png";
import DeviceQuizImg3x from "./assets/device-quiz@3x.png";
import IllustrationImg from "./assets/illu-tuto-04.svg";
import DecoImg from "./assets/deco.svg";
import React from "react";

/**
 * Landing page component styles
 */
export const useLandingPageStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        background: "#f3f3f8",
        paddingBottom: theme.spacing(3),
      },
      illustration: {
        backgroundImage: `url(${DeviceQuizImg})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom center",
        width: 300,
        minHeight: 325,
        [theme.breakpoints.down('md')]: {
          margin: 'auto',
        }
      },
      illustrationSrcSet: {
        backgroundImage: `image-set(url(${DeviceQuizImg}) 1x, url(${DeviceQuizImg2x}) 2x, url(${DeviceQuizImg3x}) 3x)`,
        backgroundSize: "cover",
        backgroundPosition: "bottom center",
        width: 300,
        minHeight: 325,
        [theme.breakpoints.down('md')]: {
          margin: 'auto',
        }
      },
      description: {
        maxWidth: 620,
        margin: 'auto',
        padding: theme.spacing(4, 2, 2, 2),
      },
      banner: {
        backgroundColor: "#030F3B",
        backgroundImage: `url(${DecoImg})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        [theme.breakpoints.down('md')]: {
          backgroundRepeat: "repeat",
          minHeight: 600,
        }
      },
      downloadDescription: {
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse',
        }
      },
      bannerTypo: {
        [theme.breakpoints.down('md')]: {
          textAlign: 'center',
          maxWidth: 260,
        },
        paddingTop: theme.spacing(5),
        letterSpacing: "1.5px",
      },
      bannerTypo1: {
        background: "#FFFFFF",
        color: theme.palette.primary.dark,
        padding: theme.spacing(1),
        display: "block",
        [theme.breakpoints.down('md')]: {
          display: 'initial',
        }
      },
      bannerTypo2: {
        background: theme.palette.primary.dark,
        color: "#FFFFFF",
        padding: theme.spacing(1),
        display: "inline-block",

      },
      bannerIllustration: {
        backgroundImage: `url(${IllustrationImg})`,
        backgroundSize: "cover",
        height: 240,
        width: 272,
        position: "absolute",
        bottom: 0,
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('md')]: {
          left: '50%',
          transform: 'translateX(-50%)',
        }
      },
      bannerIllustrationWrapper: {
        paddingBottom: 0,
        minHeight: 240,
      },
      storeImg: {
        [theme.breakpoints.down('md')]: {
          width: 186,
          margin: '2px auto 8px auto',
        },
          margin: theme.spacing(2, 4, 0, 0),
      },
      storeImgContainer: {
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 26,
        },
      },
      space: {
        display: "block",
        height: theme.spacing(6),
      },
      temoignage: {
        padding: theme.spacing(7, 2, 0, 2),
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(3),
        }
      },
      echoTypo: {
        fontSize: 27,
        [theme.breakpoints.down('md')]: {
          fontSize: 18,
        }
      },
      paperGroup: {
        marginTop: theme.spacing(4),
      },
      paperImg: {
        [theme.breakpoints.down('md')]: {
          maxWidth: 312,
        },
        "&>img": {
          width: "100%",
        },
        maxWidth: 620,
        width: "100%",
        margin: "auto",
        padding: theme.spacing(4, 0, 0, 0),
      },

    },
  ),
);

export const accountDescription = 'La certification CléA Numérique est une certification reconnue par l’organisme Certif’ Pro (association nationale pour la certification paritaire interprofessionnelle).'
export const accountDescriptionEnd = 'Les informations qui vous sont demandées ci-dessous sont nécessaires pour la création de votre dossier auprès de l’organisme certificateur.'

export enum ReservationStep {
  Reservation,
  Recap,
  Account,
  Payment,
  Confirmation,
  Resume
}

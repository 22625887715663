// @dsk-lib
import { IFeature } from '@dsk-lib/reservation/dist/services/geocode'
// Models
import { IApiSite, IUserData, Civility } from '../../services/api'
import { ISpaSite, ISpaSession, ISpaSiteWithSessionsGroupByDay } from '../../services/api.formater'
// Store
import { ReservationActionTypes, RESERVATION_ACTIONS_TYPES } from './types'
// Config
import { STORE_LOCALSTORAGE_ITEM_KEY } from '../../config/app.settings'
import moment from 'moment'

/**
 * Search Interface
 */
export interface ISearch {
  location: IFeature,
  from: Date,
  to: Date,
}

interface IPlaceInformations {
  name: string;
  address: string;
  zipcode: number | null;
  city: string;
}

/**
 * Reservation state properties
 */
export interface IReservationState {
  date: number
  currentStep: number;
  place: IPlaceInformations;
  search?: ISearch;
  session?: ISpaSession,
  site?: ISpaSite,
  siteList: IApiSite[],
  lastSearchResult: ISpaSiteWithSessionsGroupByDay[]
  userData: IUserData
  billId?: string
  reservationId?: string
  payment?: any
}

/**
 * Initial user date form
 */
const initialUserData: IUserData = {
  gender: Civility.F,
  lastName: '',
  firstName: '',
  birthName: '',
  birthContinent: '',
  birthZipCode: '',
  birthCity : '',
  phone: '',
  email: '',
  studyLevel: '',
  origin: '',
  funder: '',
  postalAddress: '',
  city: '',
  zipCode: '',
  postierId: '',
  formationId: ''
}

/**
 * Reservation initial state
 */
const initialState: IReservationState = {
  date: new Date().getTime(),
  currentStep: 0,
  payment: {
    method: '',
    cardnumber: '',
    expiration: '',
    security: '',
  },
  place: {
    name: '',
    address: '',
    zipcode: null,
    city: '',
  },
  siteList: [],
  search: undefined,
  lastSearchResult: [],
  userData: initialUserData
}

/**
 * Get initial reservation state
 */
const getInitialState = (): IReservationState => {
  try {
    const initialStateFromLocalStorage = localStorage.getItem(STORE_LOCALSTORAGE_ITEM_KEY)
    if(initialStateFromLocalStorage) {
      const stateFromStorage = JSON.parse(initialStateFromLocalStorage)
      const isExpired = !moment(stateFromStorage.exp).isSame(moment(), 'day')
      if(isExpired) {
        localStorage.removeItem(STORE_LOCALSTORAGE_ITEM_KEY)
        return initialState
      }
      return stateFromStorage
    }

    return initialState
  } catch (error) {
    localStorage.removeItem(STORE_LOCALSTORAGE_ITEM_KEY)
    return initialState
  }

}

export const clearUserData = () => {
  localStorage.clear()
}

/**
 * Reservation state reducer
 * @param state
 * @param action
 */
export default (state: IReservationState = getInitialState(), action: ReservationActionTypes): IReservationState => {
  let newState: IReservationState = state
  switch (action.type) {
    case RESERVATION_ACTIONS_TYPES.SET_CURRENT_STEP:
      newState = {...state, currentStep: action.currentStep}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_RESERVATION_SEARCH:
        newState = {...state, search: action.search, site: undefined, session: undefined, lastSearchResult: []}
      break;
    case RESERVATION_ACTIONS_TYPES.RESET_SESSION:
      newState = {...state, session: undefined}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_SESSION:
      newState = {...state, session: action.session}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_SITE_LIST:
      newState = {...state, siteList: action.siteList}
      break;
    case RESERVATION_ACTIONS_TYPES.RESET_SITE:
      newState = {...state, site: undefined}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_SITE:
      newState = {...state, site: action.site}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_LAST_SEARCH_RESULT:
      newState = {...state, lastSearchResult: action.lastSearchResult}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_USER_INFORMATION:
      newState = {...state, userData: action.userData}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_RESERVATION:
      newState = {...state, ...action.reservation}
      break;
    case RESERVATION_ACTIONS_TYPES.SET_PAYMENT_RESPONSE:
      newState = {...state, ...action.payment}
      break;
    default:
      newState = state
      break;

  }

  localStorage.setItem(STORE_LOCALSTORAGE_ITEM_KEY, JSON.stringify({...newState, exp: moment()}))

  return newState
};

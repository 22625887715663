// Core
import React from "react";

// dsk lib
import DskProfile from "@dsk-lib/profile";
import MenuGalaxy from "menu-galaxy";
import { useUserAuthentication } from "@dsk-lib/user";

// Constants
import {
  CONSTRAINT_FIELD_ENDPOINT,
  SCHOOL_API_ENDPOINT,
  USER_API_ENDPOINT,
  USER_PROFILE_VERSION,
  MENU_GALAXY,
} from "../../config/app.settings";
import { keycloakConfig } from "../../config/keyclock";

// Utils
import { getLinkUrlParams } from "../../utils/Auth";
import { Container, Grid } from "@material-ui/core";
import { useProfilePageStyle } from "./profile.style";
import { useDispatch } from "react-redux";
import { setNotification, resetNotification } from "../../store/notification/actions";
import Notification from "../../components/Notification";

/**
 * Profile props
 */
interface ProfileProps {
  /** Foo property - Display foo string */
  foo: string;
}

const profileProps = {
  schoolApiEndPoint: SCHOOL_API_ENDPOINT,
  userApiEndPoint: USER_API_ENDPOINT,
  userProfilVersion: USER_PROFILE_VERSION,
  service: keycloakConfig.clientId,
  constraintApiEndPoint: CONSTRAINT_FIELD_ENDPOINT,
};

/**
 * Profile component
 */
const Profile: React.FC<ProfileProps> = ({ foo }: ProfileProps) => {
  /** Styles */
  const classes = useProfilePageStyle(useProfilePageStyle);
  const dispatch = useDispatch();

  const sendNotification = (
    message: string,
    type: "success" | "error" | "info" | "warning"
  ) => {
    dispatch(setNotification({ type, message }));
    return { type, payload: message };
  };

  /**
   * Close notification
   */
  const closeNotification = () => {
    dispatch(resetNotification());
  };

  /** Autentication */
  const { authentication, keycloak } = useUserAuthentication();

  return (
    <>
      <MenuGalaxy
        isLogged={authentication.isAuthenticated}
        service="clea"
        config={{ url: MENU_GALAXY }}
        getLinkUrlParams={url => getLinkUrlParams(url, keycloak)}
      />
      <Container className={classes.container}>
        <Grid container={true} item={true} justify="center">
          <DskProfile
            {...profileProps}
            setNotificationMessage={sendNotification}
          />
        </Grid>
      </Container>
      <Notification onClose={closeNotification} />
    </>
  );
};

export default Profile;

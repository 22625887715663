import React from "react";
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
      maxWidth: 940,
      width: '100%',
      display: "flex",
      margin: "0 auto",
      justifyContent: "center",
    },
  })
);

interface IContainerProps {
  children: React.ReactElement;
}

export default ({ children }: IContainerProps) => {
  const style = useStyle({});
  return <Box className={style.container}>{children}</Box>;
};

import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  padding: 60px 0;
  background-color: ${(props: ISectionProps) => props.backgroundColor};
  p {
    margin-bottom: 16px;
    span.color-primary {
      color: #75b519;
    }
    &.text-center {
      text-align: center;
    }
    &.text-italic {
      font-style: italic;
    }
  }

  @media screen and (max-width: 940px) {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;

export const SectionTitle = styled.h2`
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #1e243a;
  padding-bottom: 16px;
  margin-bottom: 24px;
`;

export const SectionSubTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 24px;
`;

interface ISectionProps {
  backgroundColor?: string;
  children: any;
}

export default (props: ISectionProps) => {
  return (
    <div style={{ backgroundColor: props.backgroundColor }}>
      <Container backgroundColor={props.backgroundColor}>
        {props.children}
      </Container>
    </div>
  );
};

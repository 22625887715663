/**
 * Notification action types
 */
export enum NOTIFICATION_ACTION_TYPES {
  SET_NOTIFICATION = '@NOTIFICATION/SET_NOTIFICATION',
  RESET_NOTIFICATION = '@NOTIFICATION/RESET_NOTIFICATION',
}

/**
 * Notification properties
 */
export interface INotification {
  message: string
  type: 'success' | 'info' | 'warning' | 'error'
}

/**
 * Set notification action properties
 */
interface ISetNotification {
  type: typeof NOTIFICATION_ACTION_TYPES.SET_NOTIFICATION,
  notification: INotification
}

/**
 * Reset notification action properties
 */
interface IResetNotification {
  type: typeof NOTIFICATION_ACTION_TYPES.RESET_NOTIFICATION
}

/**
 * Notification ation types
 */
export type NotificationActionTypes = ISetNotification | IResetNotification

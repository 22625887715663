// Core
import React from 'react'

// @dsl-lib
import { LazyBackgroundImage } from '@dsk-lib/core'

// Styles
import { useBookingHeaderStyle } from './bookingHeader.style'

/**
 * Booking header properties
 */
interface IBookingHeaderProps extends React.HTMLProps<HTMLDivElement> {
  headerBgUrl: string
  headerBgPlaceholderUrl: string
}

/**
 * Booking header component
 * @param title - Title text
 * @param headerBgUrl - Background image url behind title
 * @param headerBgPlaceholderUrl - Background image url behind title
 */
const BookingHeader = ({children, headerBgUrl, headerBgPlaceholderUrl}: IBookingHeaderProps) => {
  // Component style
  const classes = useBookingHeaderStyle()

  return (
    <LazyBackgroundImage className={classes.bookingHeader} Wrapper={'div'}
                         placeholderImageUrl={headerBgPlaceholderUrl} imageUrl={headerBgUrl}>
      <div className={classes.root}>
        {children}
      </div>
    </LazyBackgroundImage>

  )

}

export default BookingHeader

// Core
import React from "react";

// Material ui
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Grid,
  Typography,
  withWidth,
  isWidthDown,
  WithWidthProps,
} from "@material-ui/core";

/**
 * PaperAbout component props
 */
export interface IPaperAboutProps extends WithWidthProps{
  img: string,
  position: "left" | "right",
  title: string,
  content: string,
  isHtml?: boolean
}

export const usePaperAboutStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperTitle: {
      "&:before": {
        position: "absolute",
        content: "''",
        width: 4,
        height: 20,
        borderRadius: 2,
        backgroundColor: "#9ed604",
        left: -12,
      },
      color: "#1b1b1b",
      fontSize: 18,
      fontWeight: "bold",
      position: "relative",
      marginLeft: 12,
      marginBottom: theme.spacing(2),
    },
    paperContent: {
      '& > a': {
        color: theme.palette.primary.dark,
        fontStyle: 'italic',
      },
    },
    paper: {
      margin: "auto",
      padding: theme.spacing(3),
      maxWidth: 940,
    },
  }));

/**
 * PaperAbout component
 */
const PaperAbout = ({ title, content, img, position, isHtml, width }: IPaperAboutProps) => {
  /** Styles */
  console.log(width)
  const classes = usePaperAboutStyles();
  const direction = width && isWidthDown('md', width) ? 'row' : (position === "right") ? "row-reverse" : "row"
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container={true} spacing={4} direction={direction}>
        <Grid item={true}>
          <img src={img} alt=""/>
        </Grid>
        <Grid item={true} xs={width && isWidthDown('md', width)? 12 : true}>
          <Typography variant="h4" className={classes.paperTitle} gutterBottom={true}>{title}</Typography>
          {
            isHtml ? <Typography className={classes.paperContent} dangerouslySetInnerHTML={{ __html: content }}/>
              : <Typography className={classes.paperContent} >{content}</Typography>
          }

        </Grid>
      </Grid>
    </Paper>
  );

};

export default withWidth()(PaperAbout);

import { ISpaSession, ISpaSite, ISpaSiteWithSessionsGroupByDay } from '../../services/api.formater'
import { IApiSite, IUserData } from '../../services/api'
import { ISearch } from './index'
import {
  RESERVATION_ACTIONS_TYPES, ReservationActionTypes,
} from './types'

export const resetSession = (): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.RESET_SESSION}
)
export const setSession = (session: ISpaSession): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_SESSION, session}
)

export const setSite = (site: ISpaSite): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_SITE, site}
)

export const resetSite = (): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.RESET_SITE}
)

export const setSiteList = (siteList: IApiSite[]): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_SITE_LIST, siteList}
)

export const setReservationCurrentStep = (currentStep: number): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_CURRENT_STEP, currentStep}
)

export const setLastSearchResult = (lastSearchResult: ISpaSiteWithSessionsGroupByDay[]): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_LAST_SEARCH_RESULT, lastSearchResult}
)

export const setReservationSearch = (search: ISearch): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_RESERVATION_SEARCH, search}
)

export const setUserInformation = (userData: IUserData): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_USER_INFORMATION, userData}
)

export const setReservation = (reservation: {reservationId: string, reservationDocaId: string}): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_RESERVATION, reservation}
)

export const setPaymentReservation = (payment: any): ReservationActionTypes => (
  {type: RESERVATION_ACTIONS_TYPES.SET_PAYMENT_RESPONSE, payment}
)

import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import reservation, { IReservationState } from './reservation'
import notification, { INotificationState } from './notification/reducer'

export interface IAppState {
  reservation: IReservationState
  notification: INotificationState
}

const reducer = combineReducers({
  reservation,
  notification
})

export default createStore(
  reducer,  composeWithDevTools(applyMiddleware(thunk)),
)

import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import PageHead from "../../components/PageHead";
import Quiz from "../Quiz";
import HomePage from "../HomePage";
import Footer from "../../components/Footer";

export default () => {
  return (
    <Fragment>
      <PageHead />
      <Route exact={true} path="/" component={HomePage} />
      <Route exact={true} path="/quiz/:id" component={Quiz} />
      <Footer />
    </Fragment>
  );
};

import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import logo1x from "./assets/images/ic-logo-super-cle-a-num.png";
import logo2x from "./assets/images/ic-logo-super-cle-a-num@2x.png";
import logo3x from "./assets/images/ic-logo-super-cle-a-num@3x.png";

const Container = styled.div``;

export default () => {
  return (
    <Container>
      <Link to="/">
        <img
          alt="Cléa Numérique logo"
          src={logo1x}
          srcSet={`${logo2x} 2x, ${logo3x} 3x`}
          className="bg-header"
        />
      </Link>
    </Container>
  );
};

// Components
import { IPaperAboutProps } from "./components/PaperAbout";
// Assets
import LogoDigi from "./assets/color.svg";
import afpaIc from "./assets/ic-afpa.svg";
import posteIc from "./assets/la-poste-logo.svg";

const PAPER_DIGI_TITLE = "digiSchool";
const PAPER_DIGI_IMG = LogoDigi;
export const PAPER_DIGI_CONTENT = `digiSchool est le leader français de l’éducation numérique avec 12 millions de 
visites par mois sur ses sites web et applications mobiles. Sa communauté éducative attire plus de 5 000 nouveaux 
membres par jour. digiSchool a pour mission de préparer les 15-25 ans à l’obtention de leurs diplômes (brevet, bac, 
code de la route, TOEIC…) et à les accompagner dans leur orientation scolaire (test d’orientation, bot de 
recommandation…)`;

const PAPER_AFPA_TITLE = "AFPA";
const PAPER_AFPA_IMG = afpaIc;
export const PAPER_AFPA_CONTENT = `Avec 113 500 personnes formées en 2019, dont 72 000 demandeurs d’emploi et 41 500 salariés, 
l’Afpa (Agence nationale pour la formation professionnelle des adultes) est un <strong>opérateur majeur de la formation 
professionnelle en Europe. Epic depuis le 1er janvier 2017 et membre du service public de l’emploi, elle accompagne 
les demandeurs d’emploi et les salariés, depuis plus de 65 ans</strong>, de la formation à l’emploi : insertion, reconversion, 
professionnalisation. Acteur majeur de l’alternance et des transitions professionnelles, l’Afpa est aussi le 
partenaire formation et conseil de plus de 6000 entreprises. Elle est également le 1er organisme de formation des 
personnes en situation de handicap. <strong>Plus d’informations sur</strong> <a href="https://www.afpa.fr" target="_blank" rel="noopener noreferer">www.afpa.fr</a>`;

const PAPER_POSTE_TITLE = "La Poste";
const PAPER_POSTE_IMG = posteIc;
export const PAPER_POSTE_CONTENT = `La Poste est une société anonyme à capitaux publics, filiale de la Caisse des dépôts et de l’Etat. Le groupe La Poste est organisé en cinq branches : Services-Courrier-Colis, La Banque Postale, Réseau La Poste, GeoPost, Numérique. Le Groupe est présent dans 47 pays sur 4 continents. Chaque jour, les 17 000 points de contact de La Poste, 1er réseau commercial de proximité de France, accueillent 1,3 million de clients. La Poste, neutre en carbone depuis 2012, distribue 21,6 milliards d’objets par an dans le monde (lettres, imprimés publicitaires et colis), 6 jours par semaine. En 2019, le Groupe a réalisé un chiffre d’affaires de 26 milliards d’euros, dont 30% à l’international, et emploie plus de 249 000 collaborateurs. La Poste s’est donné pour objectif d’accélérer sa transformation en continuant à se diversifier. Avec pour ambition de devenir la première entreprise de services de proximité humaine, pour tous, partout et tous les jours, La Poste s’engage à simplifier la vie.`;

export const PAPER_CONTENT_LIST: IPaperAboutProps[] = [
  { title: PAPER_DIGI_TITLE, content: PAPER_DIGI_CONTENT, img: PAPER_DIGI_IMG, position: "left" },
  { title: PAPER_AFPA_TITLE, content: PAPER_AFPA_CONTENT, img: PAPER_AFPA_IMG, position: "right", isHtml: true },
  { title: PAPER_POSTE_TITLE, content: PAPER_POSTE_CONTENT, img: PAPER_POSTE_IMG, position: "left" },
];

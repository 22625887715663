import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#75b519",
      dark: "#28BC6D",
    },
    secondary: {
      main: "#9ED604"
    }
  },
  typography: {
    h1: {
      fontSize: '2.3125rem',
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
    MuiButton: {
      containedPrimary: {
        borderRadius: 100,
        border: 0,
        height: 50,
        padding: "0 30px",
        boxShadow: "none",
        color: "white",
        textTransform: "inherit",
        "&:hover": {
          backgroundColor: "#68a116",
        },
      },
      outlinedPrimary: {
        borderRadius: 100,
        border: "solid #75b519 1px",
        height: 50,
        padding: "0 30px",
        boxShadow: "none",
      },
    },
    MuiLink: {
      root: {
        textDecoration: "none",
      },
    },
  },
});

export default theme;

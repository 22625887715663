// Core
import React from "react";
import { Container, Grid, Typography, WithWidthProps } from "@material-ui/core";
import { useLandingHeaderStyles } from "./header.style";

// Assets
import illustation from "../assets/top-screen.png";
import illustation2x from "../assets/top-screen@2x.png";
import illustation3x from "../assets/top-screen@3x.png";
import AppStore from "../assets/app-store.svg";
import PlayStore from "../assets/google-play-badge.svg";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";

/**
 * Header component
 */
const Header = ({width}: WithWidthProps) => {
  /** State */
  const classes = useLandingHeaderStyles();

  return (
    <header className={classes.root}>
      <Container className={classes.container}>
        <Grid container={true} justify="center">
          <Grid item={true} style={{position: 'relative'}}>
            <Typography className={classes.typography} variant="h1">
              Découvrez <br/>l’application <br/>superCléA Num !
            </Typography>
            {
              width && isWidthDown('md', width) && <Grid container={true} direction="column" style={{position: 'absolute', top: 170}}>
                <Grid item={true}>
                  <a href="https://apps.apple.com/fr/app/supercl%C3%A9a-num/id1490131484" target="_blank"
                     rel="noopener noreferrer">
                    <img src={AppStore} className={classes.storeImg} alt=""/>
                  </a>
                </Grid>
                <Grid item={true}>
                  <a href="https://play.google.com/store/apps/details?id=com.digischool.clea" target="_blank"
                     rel="noopener noreferrer">
                    <img src={PlayStore} className={classes.storeImg} alt=""/>
                  </a>
                </Grid>
              </Grid>
            }

          </Grid>
          <Grid item={true} style={width && isWidthDown('md', width) ?{paddingLeft: '30%'} : undefined}>
            <img src={illustation} srcSet={`${illustation} 1x, ${illustation2x} 2x, ${illustation3x} 3x`} alt=""/>
          </Grid>
        </Grid>
      </Container>
    </header>
  );

};

export default withWidth()(Header);

// Core
import React from "react";
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";

/**
 * CardDescription component props
 */
interface ICardDescriptionProps {
  img: string
  text: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      root: {
        padding: theme.spacing(2, 0),
        width: 240,
        margin: 'auto',
      },
      img: {
        display: "block",
        width: 140,
        height: 140,
        margin: "0 auto 16px auto",
      },
      typography: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#828999",
      },
    },
  ),
);

/**
 * CardDescription component
 */
const CardDescription = ({img, text}: ICardDescriptionProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img  className={classes.img} src={img} alt="" />
      <Typography className={classes.typography} align="center">{text}</Typography>
    </div>
  );

};

export default CardDescription;

// Api endpoint
const RESERVATION_API_ENDPOINT = process.env.REACT_APP_RESERVATION_API_ENDPOINT || ''
export const API_PAYMENT_ENDPOINT = process.env.REACT_APP_PAYMENT_API_ENDPOINT || ''

// Stripe
export const STRIPE_PRODUCT_ID = process.env.REACT_APP_STRIPE_PRODUCT_ID || ''
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || ''
// const CERTIFICATION_API_ENDPOINT = process.env.REACT_APP_CERTIFICATION_API_ENDPOINT || ''

export const API_SITES_ENDPOINT = RESERVATION_API_ENDPOINT ? `${RESERVATION_API_ENDPOINT}/sites`: ''
export const API_SESSIONS_ENDPOINT = RESERVATION_API_ENDPOINT ? `${RESERVATION_API_ENDPOINT}/sessions`: ''
export const API_RESERVATIONS_ENDPOINT =  RESERVATION_API_ENDPOINT ? `${RESERVATION_API_ENDPOINT}/reservations`: ''
export const ACTIVITY_TYPE = process.env.REACT_APP_ACTIVITY_TYPE || ''
export const STORE_LOCALSTORAGE_ITEM_KEY = process.env.REACT_APP_STORE_LOCALSTORAGE_ITEM_KEY || 'item-key'

// Galaxy menu
export const MENU_GALAXY = process.env.REACT_APP_MENU_GALAXY || ''

// Search date filter
export const MIN_DATE: { value: number, unit: 'hours' | 'minutes' | 'seconds' | 'months' | 'years' | 'days'} = {
  value: 8,
  unit: 'days',
}

// User profile
export const CONSTRAINT_FIELD_ENDPOINT = process.env.REACT_APP_CONSTRAINT_FIELD_ENDPOINT
export const SCHOOL_API_ENDPOINT = process.env.REACT_APP_SCHOOL_API_ENDPOINT || ''
export const USER_API_ENDPOINT = process.env.REACT_APP_USER_API_ENDPOINT || ''
export const USER_PROFILE_VERSION = process.env.REACT_APP_USER_PROFILE_VERSION || 'v2'

import * as React from "react";
import ReactGA from "react-ga";
import { RouteComponentProps } from "react-router-dom";
import { QuizPlayer } from "@dsk-lib/quizplayer";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";
import InfosBilan from "./InfosBilan";
import { getQuizStatus, getQuizScore } from "../../utils/QuizStatus";

import "./assets/styles/style.scss";

const Container = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > div > div {
  }
  @media screen and (max-width: 940px) {
    & > div {
      width: 100%;
    }
  }

  #score-root > div:nth-child(-n + 3) {
    display: none;
  }
`;

interface IMatchProps {
  id: string;
}
interface IQuizPageProps extends RouteComponentProps<IMatchProps> {}

interface IQuizPageState {
  content: any;
  status: boolean;
  score?: { score?: number, total?: number };
}

export default class extends React.Component<IQuizPageProps, IQuizPageState> {
  public state: IQuizPageState = {
    content: null,
    status: false,
    score: {}
  };

  constructor(props) {
    super(props);
    this.handleEndQuiz = this.handleEndQuiz.bind(this);
  }

  public async componentDidMount() {
    await this.fetchQuiz();
    ReactGA.event({
      category: `start_quiz_${this.props.match.params.id}`,
      action: "clic",
    });
  }

  public async fetchQuiz() {
    const res = await fetch(
      `${
        process.env.API_LEARNING || "https://api-digischool.kontinium.com"
      }/access/user/quiz/${this.props.match.params.id}?embed=display`
    );
    const content = await res.json();
    content.correctionMode = false;
    content.correctionStepByStep = false;
    this.setState({ content });
  }

  public async handleEndQuiz(a) {
    ReactGA.send({
      category: `end_quiz_${this.props.match.params.id}`,
      action: "clic",
    });
    this.setState({
      score: getQuizScore(this.props.match.params.id)
    });
  }

  public render() {
    return (
      <Container>
        <InfosBilan
                status={this.state.status}
                score={this.state.score}
                quizId={this.props.match.params.id}
              />
        {this.state.content ? (
          <QuizPlayer
            id="digi-quiz-player"
            showFinalScore={true}
            handleEndQuiz={this.handleEndQuiz}
            content={this.state.content as any}
            correctionStepByStep={false}
          />
        ) : (
          <CircularProgress />
        )}
      </Container>
    );
  }
}

import React from "react";
import styled from "styled-components";

import { getQuizScore, getUserBilan } from "../../utils/QuizStatus";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto, sans-serif;
  width: calc(380px + 53px + 156px);
  margin: 40px 0 50px 0;

  img {
    width: 156px;
  }
  & > div {
    margin-top: 20px;
  }
  p {
    width: 380px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    padding: 0 20px;
    flex-direction: column;
    align-items: center;
    & > div {
      margin-top: 50px;
    }
    p {
      width: 100%;
    }
  }
`;

const Title = styled.h2`
  width: calc(380px + 53px + 156px);
  font-family: Roboto;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #75b519;

  @media screen and (max-width: 940px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export default ({
  status,
  quizId,
  score,
}: {
  status: boolean;
  quizId: string;
  score?: { score?: number, total?: number };
}) => {
  const [state, setState] = React.useState<{
    name: string | null,
    image: string | null;
    title: string | null;
    content: JSX.Element | null;
  }>({ image: '', title: '', content: null, name: null });

  React.useEffect(() => {
    setState(getUserBilan(quizId))
  }, [quizId, score]);
  return (
    <>
      <Title id="test">{state.title}</Title>
      <Container>
        { state.image && <img src={state.image} alt="logo bilan" />}
        <div>{state.content}</div>
      </Container>
    </>
  );
};

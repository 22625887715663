import * as React from "react";
import styled from "styled-components";

import graphImg from "./assets/images/ic-graphique.svg";

const Container = styled.div`
  display: flex;
  margin-top: 40px;

  @media screen and (max-width: 940px) {
    flex-direction: column;
  }
`;

interface IDotProps {
  color?: string;
}
const Dot = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: ${(props: IDotProps) => props.color};
`;

interface ILegendProps {
  color?: string;
  text: string;
}
const Legend = ({ color, text }: ILegendProps) => (
  <div style={{ display: "flex" }}>
    <Dot color={color} />
    <span style={{ lineHeight: "27px", marginLeft: "16px" }}>{text}</span>
  </div>
);

const LegendContainer = styled.div`
  height: 129px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const InfosContainer = styled.div`
  width: calc(100% - 300px);
  margin-left: 16px;
  p {
    margin-top: 16px;
  }

  @media screen and (max-width: 940px) {
    width: 100%;
  }
`;

export default () => {
  return (
    <Container>
      <div style={{ textAlign: "center", marginBottom: 17 }}>
        <img src={graphImg} width="300px" alt="graphique" />
      </div>
      <InfosContainer>
        <LegendContainer>
          <Legend
            text="7 % sont exclus et sans accès au numérique"
            color="#293618"
          />
          <Legend
            text="19 % ont peu ou pas de compétences dans l’usage du numérique"
            color="#75b519"
          />
          <Legend
            text="14 % ont un niveau avancé mais ont des craintes et manquent de motivation face au numérique"
            color="#c9e6a1"
          />
        </LegendContainer>
        <p>
          La maîtrise des usages numériques est un facteur clé de succès dans la
          recherche d’emploi et l’insertion professionnelle, en particulier pour
          les personnes les moins qualifiées. Par ailleurs, la digitalisation
          des services qui est engagée renforce le risque pour les publics les
          plus fragiles de ne plus avoir accès plus avoir à des services
          essentiels.
        </p>
        <p>
          SOURCE :{" "}
          <span style={{ fontStyle: "italic" }}>
            les cahiers de l’inclusion numérique (avril 2018).
          </span>
        </p>
      </InfosContainer>
    </Container>
  );
};

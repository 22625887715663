// Core
import React from 'react'

// @dsk-lib
import { UserInformationsCard } from '@dsk-lib/reservation'

// Material-ui
import { Box, Typography } from '@material-ui/core'

// Router
import { RouteComponentProps, Redirect } from 'react-router'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { setReservationCurrentStep, setUserInformation, setReservation } from '../../store/reservation/actions'

// Components
import { stepList } from './index'

// Constant
import { accountDescription, accountDescriptionEnd, ReservationStep } from './reservation.constant'
import { useUserAuthentication } from '@dsk-lib/user'
import { createReservation, IUserData } from '../../services/api'
import { IAppState } from '../../store'
import Form, { validationSchema } from './components/Form'
import { setNotification } from '../../store/notification/actions'

export const flattenFormData = (userData: IUserData) => {
  return {...userData, phone: userData.phone.replace(/ /g, '')}
}

/**
 * Account component
 * @param history - History router
 */
export const Account = ({history}: RouteComponentProps) => {
  /** Store */
  const {site, session, userData} = useSelector((state: IAppState) => state.reservation)
  const dispatch = useDispatch()

  /** State */
  const [isSending, setIsSending] = React.useState<boolean>(false)

  /** Authentication hook */
  const {fetchWithCredentials, keycloak, authentication} = useUserAuthentication()
  // Redirect if user is not logged in to avoid errors
  if (!authentication.isAuthenticated) {
    history.push("/reservation/recherche")
    dispatch(setReservationCurrentStep(0));
  }

  const userAutoCompleteDate: IUserData = {
    ...userData,
    email: userData.email || keycloak && keycloak.tokenParsed.email,
  }

  /** Contants */
  const place = site && {zipcode: parseInt(site.zipcode, 10), name: site.name, address: site.address, city: site.city}
  const nextStep = ReservationStep.Payment
  /**
   * Handle next step
   * @param userFormData
   */
  const handleNextStep = async(userFormData: IUserData) => {
    setIsSending(true)
    dispatch(setUserInformation(userFormData))
    const reservation = await createReservation(fetchWithCredentials, session!.id, flattenFormData(userFormData))
    if(reservation.success) {
      dispatch(setReservationCurrentStep(nextStep))
      dispatch(setReservation({reservationDocaId: reservation.data.idReservation, reservationId: reservation.data._id}))
      history.push(stepList[nextStep].slug)
    } else {
      const message = reservation.status === 401 ? "Votre session à expirée, veuillez vous reconnecter." : 'Une erreur est survenue lors de l\'enregistrement, veuillez réessayer ultérieurement, ou utiliser le lien contact en bas de page.'
      dispatch(setNotification({message, type: 'error'}))
    }
    setIsSending(false)
  }

  if(!site) {
    dispatch(setReservationCurrentStep(0))
    return <Redirect to="/reservation/recherche" />
  }

  return (
    <Box style={{maxWidth: 788, width: '100%'}}>
      <UserInformationsCard
        header={true}
        place={place}
        date={new Date(session!.openingTime)}
        description={<Typography>{accountDescription}<strong>{accountDescriptionEnd}</strong></Typography>}
        initialValues={userAutoCompleteDate as any}
        onSubmit={handleNextStep as (a: any) => Promise<void>}
        validationSchema={validationSchema}
        Form={Form as any}
        submissionDisabled={isSending}
      />
    </Box>
  )
};

export default Account

import * as React from "react";
import { Modal, Button } from "@material-ui/core";
import styled from "styled-components";
import { getLinkUrlParams } from "../../utils/Auth";

import headBg from "./assets/images/header.svg";
import { useUserAuthentication } from '@dsk-lib/user'

const ModalHead = styled.div`
  background: url(${headBg});
  height: 98px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  h3 {
    color: white;
    font-family: Roboto, sans-serif;
    font-size: 31px;
    margin: 0;
  }
`;
const ModalContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  background-color: white;
`;

const ModalContent = styled.div`
  margin-top: 23px;
  font-family: Roboto, sans-serif;
  margin-bottom: 24px;
  padding: 0 32px;
  text-align: center;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  .MuiButtonBase-root {
    padding: 0;
    width: 150px;
    font-size: 14px;
    text-transform: inherit;
    margin: 0 12px;
  }
  a {
    text-decoration: none;
  }
`;

interface IModalProps {
  open?: boolean;
  title: string;
  children: any;
  buttonOne?: string;
  buttonOneTo?: string;
  buttonTwo?: string;
  buttonTwoTo?: string;
}

export default ({
  open,
  title,
  buttonOne,
  buttonOneTo,
  buttonTwo,
  buttonTwoTo,
  children,
}: IModalProps) => {
  const {keycloak} = useUserAuthentication()
  const triggerKeyCloak = (url: string) => () => getLinkUrlParams(url, keycloak);
  return (
    <Modal
      open={open || false}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModalContainer>
        <ModalHead>
          <h3>{title}</h3>
        </ModalHead>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          {buttonOne && buttonOneTo && (
            <Button
              variant="contained"
              color="primary"
              onClick={triggerKeyCloak(buttonOneTo)}
            >
              {buttonOne}
            </Button>
          )}
          {buttonTwo && buttonTwoTo && (
            <Button
              variant="outlined"
              color="primary"
              onClick={triggerKeyCloak(buttonTwoTo)}
            >
              {buttonTwo}
            </Button>
          )}
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

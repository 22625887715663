// Core
import React from 'react'

// dsk-lib

// Material
import {
  Box,
  Typography,
  TextField,
  Link,
  Grid,
  FormControl,
  InputAdornment, useTheme, fade,
} from '@material-ui/core'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import CardIcon from '@material-ui/icons/CreditCard'
import CardCCV from '@material-ui/icons/Lock'

// Store
import { useSelector, useDispatch } from 'react-redux'
import {
  setReservationCurrentStep,
  setPaymentReservation,
} from '../../store/reservation/actions'
import { stepList } from './index'
import { RouteComponentProps, Redirect } from 'react-router'
import { ReservationCard } from '@dsk-lib/reservation/dist'
import { IAppState } from '../../store'
import { ReservationStep } from './reservation.constant'
import { useUserAuthentication } from '@dsk-lib/user'
import {
  checkCodeValidation,
  createInvoice,
  consumeCodeForPayment,
  createSetupIntent,
} from '../../services/api'
import { setNotification } from '../../store/notification/actions'
import { flattenFormData } from './Account'
import { STRIPE_API_KEY } from '../../config/app.settings'
import {
  StripeProvider,
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements'
// import InputMask from "react-input-mask";
import { usePaymentStyle } from './payment.style'
import ButtonAsync from '../../components/ButtonAsync'
import StripeElementWrapper from './components/StripeElementWrapper'

export default ({history}: RouteComponentProps) => {

  /** Authentication hook */
  const {fetchWithCredentials} = useUserAuthentication()

  /** Store */
  const {site, session, userData, reservationId} = useSelector(
    (state: IAppState) => state.reservation,
  )
  const dispatch = useDispatch()

  /** States */
  const [localStep, setLocalStep] = React.useState<number>(0)
  const [code, setCode] = React.useState<string>('')
  const [codeError, setCodeError] = React.useState<string>('')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [
    stripeInstance,
    setStripeInstance,
  ] = React.useState<null | stripe.Stripe>(null)

  /** Constants */
  const nextStep = ReservationStep.Confirmation
  const isStripePayment = localStep === 1
  const initialPaymentValues = {cardNumber: '', expiration: '', security: ''}

  /** Styles */
  const classes = usePaymentStyle()

  React.useEffect(() => {
    const stripeScript = document.querySelector('#stripe-js')
    console.log(stripeScript)
    if (!stripeInstance && isStripePayment && stripeScript && window.Stripe) {
      setStripeInstance(window.Stripe(STRIPE_API_KEY))
    } else if (isStripePayment && stripeScript && !window.Stripe) {
      stripeScript.addEventListener('load', () => {
        setStripeInstance(window.Stripe(STRIPE_API_KEY))
        console.log('stripe ready')
      })
    }
  }, [isStripePayment])

  const handleCodeValidation = async () => {
    setLoading(true)
    const codeValidation = await checkCodeValidation(
      fetchWithCredentials,
      code,
    )

    if (codeValidation.success) {
      const invoice = await createInvoice(
        fetchWithCredentials,
        flattenFormData(userData),
        reservationId,
      )
      if (invoice.success) {
        const payment = await consumeCodeForPayment(
          fetchWithCredentials,
          invoice.data._id,
          flattenFormData(userData),
          code,
        )
        if (payment.success) {
          dispatch(setPaymentReservation(payment))
          dispatch(setReservationCurrentStep(nextStep))
          history.push(stepList[nextStep].slug)
        } else {
          dispatch(
            setNotification({
              message:
                'Une erreur est survenue lors de la validation de votre coupon.',
              type: 'error',
            }),
          )
        }
      } else {
        dispatch(
          setNotification({
            message:
              'Une erreur est survenue lors de la validation de votre coupon.',
            type: 'error',
          }),
        )
      }
      setLoading(false)
    } else {
      setCodeError('Coupon invalide.')
      setLoading(false)
    }
  }

  const handleCodeFieldUpdate = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCodeError('')
    evt.persist()
    setCode(evt.target.value)
  }

  const handleNextStep = () => {
    dispatch(setReservationCurrentStep(nextStep))
    history.push(stepList[nextStep].slug)
  }

  if (!site) {
    dispatch(setReservationCurrentStep(0))
    return <Redirect to="/reservation/recherche"/>
  }

  const place = {
    zipcode: parseInt(site.zipcode, 10),
    name: site.name,
    address: site.address,
    city: site.city,
  }
  return (
    <>
      <Box style={{maxWidth: 788, width: '100%'}}>
        {localStep === 0 ? (
          <ReservationCard
            header={true}
            place={place}
            date={new Date(session!.openingTime)}
          >
            <Grid
              container={true}
              spacing={3}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item={true}>
                <Box pb={5}>
                  <Typography align="center">
                    <strong>J'ai un code d'activation :</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true}>
                <FormControl>
                  <TextField
                    value={code}
                    error={!!codeError}
                    helperText={codeError}
                    placeholder="ex : JuX56aBY"
                    onChange={handleCodeFieldUpdate}
                  />
                </FormControl>
              </Grid>
              <Grid item={true}>
                <ButtonAsync
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleCodeValidation}
                  waiting={loading}
                  disabled={loading || !code.length}
                >
                  Valider
                </ButtonAsync>
              </Grid>
              <Grid item={true}>
                <Link
                  role="button"
                  className={classes.link}
                  onClick={() => setLocalStep(1)}
                >
                  Utiliser un autre mode de paiement
                </Link>
              </Grid>
            </Grid>
          </ReservationCard>
        ) : (
          <StripeProvider stripe={stripeInstance}>
            <Elements>
              <StripeHoc
                onSubmit={handleNextStep}
                initialValues={initialPaymentValues}
                reservationId={reservationId}
                goToCoupon={() => setLocalStep(0)}
                userData={userData}
                place={place}
                date={new Date(session!.openingTime)}
              />
            </Elements>
          </StripeProvider>
        )}
      </Box>
    </>
  )
};

const StripeHoc = injectStripe((props: any) => {
  /** Styles */
  const classes = usePaymentStyle()

  const dispatch = useDispatch()
  const {fetchWithCredentials} = useUserAuthentication()
  const [loading, setLoading] = React.useState<boolean>(false)

  const {
    stripe,
    onSubmit,
    userData,
    reservationId,
    place,
    date,
    goToCoupon,
  } = props

  const displayError = () => {
    dispatch(
      setNotification({
        message:
          'Une erreur est survenue lors de la transaction. La transaction a été annulée.',
        type: 'error',
      }),
    )
  }

  const handlePayment = async () => {
    setLoading(true)
    const user = flattenFormData(userData)
    const invoice = await createInvoice(
      fetchWithCredentials,
      user,
      reservationId,
    )

    if (invoice.success) {
      const intent = await createSetupIntent(
        fetchWithCredentials,
        invoice.data._id,
        user,
      )

      if (intent.success) {
        stripe.handleCardSetup(intent.data.client_secret).then(res => {
          if (res.error) {
            displayError()
          } else {
            onSubmit()
          }
          setLoading(false)
        })
      } else {
        displayError()
        setLoading(false)
      }
    } else {
      displayError()
      setLoading(false)
    }
  }

  return (
    <Box style={{maxWidth: 788, width: '100%', minHeight: 500}}>
      <ReservationCard place={place} header={true} date={date}>
        <Grid
          container={true}
          spacing={4}
          justify="center"
          align-content="center"
        >
          <Grid item={true} xs={12}>
            <Box pl={2} pr={2} className={classes.secureText}>
              <Grid container={true} direction="row" justify="center">
                <Grid item={true}>
                  <CardCCV/>
                </Grid>
                <Grid item={true}>
                  <Typography variant="subtitle1" align="center">
                    Connexion sécurisée SSL
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="body2" align="center" color="textSecondary">
                Vos informations bancaires sont cryptées et traitées directement
                par Stripe, leader du paiement sécurisé en ligne
              </Typography>
            </Box>
          </Grid>
          <Grid
            item={true}
            container={true}
            sm={6}
            style={{display: 'flex'}}
            justify="flex-end"
            direction="column"
          >
            <StripeElementWrapper label="Numéro de carte"
                                  component={CardNumberElement}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <CardIcon fontSize="small" color="disabled"/>
                                      </InputAdornment>),
                                  }}
            />
          </Grid>

          <Grid
            item={true}
            container={true}
            sm={3}
            style={{display: 'flex'}}
            justify="flex-end"
            direction="column"
          >
            <StripeElementWrapper label="Date d'expiration"
                                  component={CardExpiryElement}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <CalendarIcon fontSize="small" color="disabled"/>
                                      </InputAdornment>),
                                  }}
            />

          </Grid>

          <Grid
            item={true}
            container={true}
            sm={3}
            style={{display: 'flex'}}
            justify="flex-end"
            direction="column"
          >
            <StripeElementWrapper label="Code de sécurité"
                                  component={CardCvcElement}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <CardCCV fontSize="small" color="disabled"/>
                                      </InputAdornment>),
                                  }}

            />
          </Grid>
          <Grid item={true} container={true} xs={12} justify={'center'}>
            <ButtonAsync
              waiting={loading}
              className={classes.btnPayment}
              color="primary"
              variant="contained"
              onClick={handlePayment}
            >
              Payer
            </ButtonAsync>
          </Grid>
          <Grid item={true} container={true} xs={12} justify={'center'}>
            <Link role="button" className={classes.link} onClick={goToCoupon}>
              Utiliser un coupon
            </Link>
          </Grid>
        </Grid>
      </ReservationCard>
    </Box>
  )
})

import { makeStyles, createStyles } from '@material-ui/core'

export const usePaymentStyle = makeStyles(() => createStyles({
    link: {
      color: 'white'
    },
    btnPayment: {
      width: '100%',
      maxWidth: 240,
    },
    cvc: {
      maxWidth: 90,
    },
    secureText: {
      maxWidth: 420,
      margin: 'auto',
    },
  }),
)

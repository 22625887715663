import * as React from "react";
import styled from "styled-components";
import Background from "./Background";
import Logo from "./Logo";

import illustration1 from "./assets/images/illu-header-clea.png";
import illustration2 from "./assets/images/illu-header-clea@2x.png";
import illustration3 from "./assets/images/illu-header-clea@3x.png";

const Container = styled.div`
  position: relative;
`;
const Content = styled.div`
  position: relative;
  max-width: 940px;
  width: 100%;
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (max-width: 940px) {
    width: 100%;
  }
`;

const Separator = styled.div`
  width: 100px;
  height: 3px;
  background: #fff;
  margin: 25px 0 0 0;
  border-radius: 10px;
`;

const Display = styled.div`
  display: flex;
  margin-top: 50px;
  width: 100%;
  @media screen and (max-width: 940px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &>img {
      margin-bottom: 40px;
    }
  }
`;

const TitleContainer = styled.div`
  margin: 0 0 0 80px;
  @media screen and (max-width: 940px) {
    margin: 0 0 0 0;
  }
`

const Title = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #000000;

  span.title-more {
    color: #ffffff;
  }
  @media screen and (max-width: 940px) {
    width: calc(100% - 40px);
    font-size: 42px;
    text-align: center;
  }
`;

const SubTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #000000;
  margin: 100px 0 0 0;
  span.mmw {
    font-family: MarkMyWords, serif;
  }
  span.green {
    font-weight: bold;
    color: #75b519;
  }

  @media screen and (max-width: 940px) {
    margin: 20px 0 0 0;
    text-align: center;
  }
`

export default () => {
  return (
    <Container>
      <Background />
      <Content>
        <Logo />
        <Separator />
        <Display>
          <img
            style={{ width: 411, height: 374 }}
            alt="Cléa Numérique logo"
            src={illustration1}
            srcSet={`${illustration2} 2x, ${illustration3} 3x`}
            className="bg-header"
          />
          <TitleContainer>
            <Title>
              Quel utilisateur<br />
              du numérique<br />
              êtes-vous ?<br />
            </Title>
            <SubTitle>
              <span className="mmw">Passez le test</span><br />
              ET FORMEZ-VOUS<br />
              AU <span className="green">CLÉA NUMÉRIQUE !</span><br />
            </SubTitle>
          </TitleContainer>
        </Display>
      </Content>
    </Container>
  );
};

import { ISpaSession, ISpaSite, ISpaSiteWithSessionsGroupByDay } from '../../services/api.formater'
import { IApiSite, IUserData } from '../../services/api'
import { ISearch } from './index'

export enum RESERVATION_ACTIONS_TYPES {
  SET_CURRENT_STEP = '@RESERVATION/SET_CURRENT_STEP',
  SET_RESERVATION_SEARCH = '@RESERVATION/SET_RESERVATION_SEARCH',
  SET_SESSION = '@RESERVATION/SET_SESSION',
  RESET_SESSION = '@RESERVATION/RESET_SESSION',
  SET_SITE = '@RESERVATION/SET_SITE',
  RESET_SITE = '@RESERVATION/RESET_SITE',
  SET_SITE_LIST = '@RESERVATION/SET_SITE_LIST',
  SET_LAST_SEARCH_RESULT = '@RESERVATION/SET_LAST_SEARCH_RESULT',
  SET_USER_INFORMATION = '@RESERVATION/SET_USER_INFORMATION',
  SET_RESERVATION = '@RESERVATION/SET_RESERVATION',
  SET_PAYMENT_RESPONSE = '@RESERVATION/SET_PAYMENT_RESPONSE'
}

interface IResetSession {
  type: typeof RESERVATION_ACTIONS_TYPES.RESET_SESSION,
}

interface ISetSession {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_SESSION,
  session: ISpaSession
}

interface ISetSite {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_SITE,
  site: ISpaSite
}

interface IResetSite {
  type: typeof RESERVATION_ACTIONS_TYPES.RESET_SITE,
}

interface ISetSiteList {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_SITE_LIST,
  siteList: IApiSite[]
}

interface ISetReservationCurrentStep {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_CURRENT_STEP,
  currentStep: number
}

interface ISetLastSearchResult {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_LAST_SEARCH_RESULT,
  lastSearchResult: ISpaSiteWithSessionsGroupByDay[]
}

interface ISetReservationSearch {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_RESERVATION_SEARCH,
  search: ISearch
}

interface ISetUserInformation {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_USER_INFORMATION,
  userData: IUserData
}

interface ISetReservation {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_RESERVATION,
  reservation: {reservationId: string, reservationDocaId: string}
}

interface ISetPaymentResponse {
  type: typeof RESERVATION_ACTIONS_TYPES.SET_PAYMENT_RESPONSE,
  payment: any
}

export type ReservationActionTypes =
  ISetSession
  | IResetSession
  | ISetSite
  | IResetSite
  | ISetSiteList
  | ISetReservationCurrentStep
  | ISetLastSearchResult
  | ISetReservationSearch
  | ISetUserInformation
  | ISetReservation
  | ISetPaymentResponse

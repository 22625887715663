// Core
import React from 'react'

// Material ui
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// Models
import { INotificationState } from '../../store/notification/reducer'

// Store
import { useSelector } from 'react-redux'
import { IAppState } from '../../store'

/**
 * Notification component props
 */
interface INotificationProps extends INotificationState {
  onClose?: (reason?: string) => void
}

/**
 * Notification component
 */
const Notification = (props: INotificationProps) => {
  /** State */
  const [open, setOpen] = React.useState<boolean>(!!props.notification || false)
  /** Store */
  const { notification } = useSelector((state: IAppState) => state.notification)

  const currentNotification = notification || props.notification

  /** Effect notification update */
  React.useEffect(() => {
    if (notification) {
      setOpen(true)
    }
  }, [notification])

  /**
   * Handle notification close
   * @param _event
   * @param reason
   */
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if(reason === 'clickaway') {
      return
    }
    if(props.onClose) {
      props.onClose(reason)
    }

    setOpen(false)
  }

  return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {
          currentNotification && <Alert onClose={handleClose} severity={currentNotification.type}>
            {currentNotification.message}
          </Alert>
        }
      </Snackbar>
  )

}

export default Notification

// Core
import React from "react";

// Styles
import styled from "styled-components";

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 55px;

  @media screen and (min-width: 640px) and (max-width: 880px) {
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
  img {
    transition: transform 0.3s;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 24px;
  }
`;

const Container = styled.div`
  width: 220px;

  &:hover {
    img {
      transform: translateY(-5px);
    }
    p {
      transform: translateY(5px);
    }
  }
  @media screen and (min-width: 640px) and (max-width: 880px) {
    margin: 0 20px 29px 20px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 29px;
  }
`;

const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  transition: transform 0.3s;
`;

type ISkillsProps =  ISkillsDefaultProps & (ISkillsTextProps | ISkillsChildrenProps)

interface ISkillsDefaultProps {
  image: string;
  image2x?: string;
  image3x?: string;
  alt: string;
  height?: number;
}

interface ISkillsTextProps {
  text: string;
  children?: never
}

interface ISkillsChildrenProps {
  text?: never;
  children: React.ReactNode
}

export default ({ alt, image2x, image3x, image, children, height, text }: ISkillsProps) => {
  const srcSet = () => {
    let res = "";
    res += image2x ? `${image2x} 2x` : "";
    res += image3x ? `, ${image3x} 3x` : "";
    return res;
  };

  return (
    <Container>
      <ImageContainer>
        <img src={image} srcSet={srcSet()} alt={alt} style={{height: height || 100}} />
      </ImageContainer>
      { text ?  <Text>{text}</Text> : children }

    </Container>
  );
};

import React from 'react'
import quiz9234 from "../assets/images/ic-occasionnel.svg";
import quiz9235 from "../assets/images/ic-regulier.svg";
import quiz9236 from "../assets/images/ic-connecte.svg";

const occasionnel = {
  name: 'Occasionnel',
  image: quiz9234,
  title:
    "Vous êtes un utilisateur occasionnel ! Vous utilisez des outils mais n’exploitez pas encore toutes leurs possibilités.",
  content: (
    <>
      <p>
        Nous vous invitons à renforcer vos connaissances en accédant à la partie
        application du site et en téléchargeant l’application mobile superCléA
        Num. Grâce aux leçons et quiz présents dans l’application, vous pourrez
        gagner en autonomie, faire des recherches plus efficaces sur internet,
        partager du contenu avec d’autres personnes et découvrir de nombreux
        outils numériques...
      </p>
      <p>
        Identifiez les questions où vous avez perdu des points et consultez les
        explications pour progresser.
      </p>
    </>
  ),
};

const regulier = {
  name: 'Regulier',
  image: quiz9235,
  title:
    "Vous êtes un utilisateur régulier ! Vous êtes à l’aise avec le numérique mais vous pouvez encore vous améliorer !",
  content: (
    <>
      <p>
        Nous vous invitons à renforcer vos connaissances en accédant à la partie
        application du site et en téléchargeant l’application mobile superCléA
        Num. Grâce aux leçons et quiz présents dans l’application, vous pourrez
        aller plus loin dans vos méthodes de recherches d’informations et mieux
        repérer les risques liés à la sécurité informatique.
      </p>
      <p>
        Identifiez les questions où vous avez perdu des points et consultez les
        explications pour progresser.
      </p>
    </>
  ),
};

const connecte = {
  name: 'Connecté',
  image: quiz9236,
  title:
    "Vous êtes un utilisateur connecté ! Vous maîtrisez les fondamentaux du numérique !",
  content: (
    <>
      <p>
        N’hésitez pas à vérifier si vous êtes à l’aise avec l’ensemble du
        contenu d'entraînement en téléchargeant l’application mobile superCléA
        Num.
      </p>
      <p>
        Pensez ensuite à la certification CléA Numérique pour faire reconnaître
        vos compétences ! Identifiez les questions où vous avez perdu des points
        et consultez les explications pour progresser.
      </p>
    </>
  ),
};

export const getQuizStatus = (quizScore: string, threshold: number) => {
  const grade = quizScore.split("/").map(value => parseInt(value, 10));
  const status = grade[0] / grade[1];
  return status >= threshold;
};

export interface IUserAnswers {
  /** Suquestion data */
  [subquestionId: number]: any;
}

export interface IQuizData {
  answers: IUserAnswers;
  currentQuestion: number;
  finish: boolean;
  totalQuestion: number;
  userScore: number;
  correctionStepByStep: boolean;
  correctionMode: boolean;
  audioActive: boolean;
  autoPlay: boolean;
  totalScore: number;
}

export const getQuizData = (id: string) => {
  return JSON.parse(
    window.localStorage.getItem(`${window.location.host}-quiz`) ?? "{}"
  )[id] as IQuizData;
}

export const getQuizScore = (id: string): { score: number, total: number } => {
  const quizData = getQuizData(id)
  return {
    score: quizData.userScore,
    total: quizData.totalScore
  }
};


export const getUserBilan = (id: string) => {
  const data = getQuizData(id)
  if (data && data.finish) {
      if (data.userScore >= 31 && data.userScore <= 40) {
        return connecte
      } else if (data.userScore >= 15 && data.userScore <= 30) {
        return regulier
      } else if (data.userScore >= 0 && data.userScore <= 14) {
        return occasionnel
      }
  }
  return {
    name: null,
    image: null,
    title: null,
    content: null
  }

}

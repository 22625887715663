const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL ||
  "https://preprodaccount.digischool.io/auth";
const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || "digiSchool";
const KEYCLOAK_CLIENT_ID =
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "clea";

export const keycloakConfig = {realm: KEYCLOAK_REALM, url: KEYCLOAK_URL, clientId: KEYCLOAK_CLIENT_ID}
export const keycloakInitOptions = { silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html` }

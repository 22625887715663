// Core
import React from 'react'

// Material ui
import {
  Box,
  FormControlLabel,
  Radio,
  FormControl,
  InputAdornment,
  Typography,
  FormHelperText,
  TextField,
  RadioGroup,
  Select, MenuItem, InputLabel, Grid, Checkbox,
} from '@material-ui/core'
import { Phone, Email } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

// External lib
import { FormikProps } from 'formik'
import MomentUtils from '@date-io/moment'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

// Services
import { IUserData, Civility } from '../../../services/api'
import { useStyles } from './form.style'
import ButtonAsync from '../../../components/ButtonAsync'

/** Phone regex */
const phoneRegex = /^(0)[1-79]( \d{2}){4}$/
const phoneMatchProps = {
  message: 'Numéro de téléphone invalide.',
  excludeEmptyString: true,
}
/** Name regex */
const nameRegex = /^[a-zA-ZÀ-ÿ '-]+$/
const nameMatchProps = {
  message: 'Seul les caractères alphabétique sont autorisés dans ce champ.',
  excludeEmptyString: true,
}
/** Postal code regex */
const postalCodeRegex = /^(?:[0-8]\d|9[0-8])\d{3}$/
const postalCodeMatchProps = {
  message: 'Code postal invalide.',
  excludeEmptyString: true,
}

/**
 * Validation form schema
 */
export const validationSchema = Yup.object({
  gender: Yup.string()
  .required('Ce champ est requis'),
  firstName: Yup.string()
  .matches(nameRegex,nameMatchProps)
  .min(2, 'Ce champ doit contenir au moins 2 caractères')
  .required('Prénom requis'),
  birthName: Yup.string()
  .matches(nameRegex, nameMatchProps)
  .min(2, 'Ce champ doit contenir au moins 2 caractères'),
  lastName: Yup.string()
  .matches(nameRegex, nameMatchProps)
  .min(2, 'Ce champ doit contenir au moins 2 caractères')
  .required('Nom requis'),
  studyLevel: Yup.string()
  .required("Niveau d'étude requis"),
  origin: Yup.string()
  .required("L'origine de votre démarche est requise"),
  funder: Yup.string()
  .required("Le financeur de votre démarche est requise"),
  birthday: Yup.date('Date de naissance non valide.')
  .required('Date de naissance requise'),
  birthContinent: Yup.string()
  .required('Continent de naissance requis'),
  birthCity: Yup.string()
  .required('Ville de naissance requis'),
  phone: Yup.string()
  .matches(phoneRegex, phoneMatchProps)
  .required('Numéro de téléphone requis'),
  email: Yup.string()
  .email('Entrez un email valide')
  .required('Email requis'),
  postalAddress: Yup.string()
  .required("L'adresse postale est requise"),
  city: Yup.string()
  .required("La ville est requise"),
  postierId: Yup.string().when('postier', { is: true, then: Yup.string().required('Veuillez remplir votre identifiant La poste')}),
  formationId: Yup.string(),
  zipCode: Yup.string()
  .matches(postalCodeRegex, postalCodeMatchProps)
  .required('Code postal requis'),
})

/** Continent enu */
enum Continent {
  America = 'Amérique',
  Africa = 'Afrique',
  Asia = 'Asie',
  Europe = 'Europe',
  Oceania = 'Océanie'
}

/** Continent list */
const continentList: Continent[] = [
  Continent.Europe,
  Continent.America,
  Continent.Africa,
  Continent.Asia,
  Continent.Oceania,
]

enum StudyLevel {
  None = "Non concerné",
  Brevet = "Brevet",
  CapBep = "CAP / BEP",
  Bac = "Bac",
  BacPlus1 = "Bac+1",
  BacPlus2 = "Bac+2 / BTS / DUT",
  BacPlus3 = "Bac+3 / Licence / Bachelor",
  BacPlus4 = "Bac+4 / Master 1",
  BacPlus5 = "Bac+5 / Master 2",
  BacPlus8 = "Bac+8"
}

const studyLevelList: StudyLevel[] = [
  StudyLevel.None,
  StudyLevel.Brevet,
  StudyLevel.CapBep,
  StudyLevel.Bac,
  StudyLevel.BacPlus1,
  StudyLevel.BacPlus2,
  StudyLevel.BacPlus3,
  StudyLevel.BacPlus4,
  StudyLevel.BacPlus5,
  StudyLevel.BacPlus8
]

enum Origin {
  PoleEmploi = "Orientation Pôle Emploi",
  MissionsLocales = "Orientation missions locales",
  CapEmploi = "Orientation CAP EMPLOI",
  Employeur = "Orientation employeur",
  TransitionPro = "Orientation Transition'Pro",
  Personal = "Initiative individuelle"
}

const originList: Origin[] = [
  Origin.PoleEmploi,
  Origin.MissionsLocales,
  Origin.CapEmploi,
  Origin.Employeur,
  Origin.TransitionPro,
  Origin.Personal
]

enum Funder {
  ConseilRégional = "Conseil Régional",
  PoleEmploi = "Pôle Emploi",
  CapEmploi = "CAP Emploi",
  Employeur = "Employeur",
  OPCO = "OPCO",
  PLIE = "PLIE",
  Personal = "Initiative individuelle"
}

const funderList: Funder[] = [
  Funder.ConseilRégional,
  Funder.PoleEmploi,
  Funder.CapEmploi,
  Funder.Employeur,
  Funder.OPCO,
  Funder.PLIE,
  Funder.Personal
]

/**
 * Form component props
 */
interface IFormProps extends FormikProps<IUserData> {
  onSubmit: (userData: IUserData) => void
  submissionDisabled?: boolean
}


/**
 * Form component
 */
const Form = ({
                values,
                errors,
                touched,
                handleChange,
                isValid,
                setFieldTouched,
                setFieldValue,
                onSubmit,
                submissionDisabled,
              }: IFormProps) => {
  /** Styles */
  const classes = useStyles()
  /** States */
  const [localValues, setLocalValues] = React.useState<IUserData>(values)
  const [postier, setPostier] = React.useState(false)

  const handlePostier = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostier(event.target.checked)
  }

  const {
    gender,
    lastName,
    birthName,
    firstName,
    birthZipCode,
    birthContinent,
    birthCity,
    birthday, email,
    phone,
    studyLevel,
    origin,
    funder,
    postalAddress,
    city,
    zipCode,
    postierId,
    formationId,
  } = localValues

  /**
   * Handle form change
   */
  const change = (name: string, e: React.ChangeEvent<any>) => {
    e.persist()
    handleChange(e)
    setLocalValues((localVal) => ({...localVal, [name]: e.target.value}))
    setFieldTouched(name, true, false)
  }

  /**
   * Handle form radio input change
   */
  const handleRadioInputChange = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    setLocalValues((localVal) => ({...localVal, [fieldName]: fieldValue}))
    setFieldValue(fieldName, fieldValue)
    setFieldTouched(fieldName, true, false)
  }

  /**
   * Handle date change
   */
  const handleDateChange = (date: any | null) => {
    if (date) {
      setLocalValues((localVal) => ({...localVal, birthday: date}))
      setFieldValue('birthday', date)
      setFieldTouched('birthday', true, false)
    }
  }

  /**
   * Handle form submission
   */
  const handleSubmit = (evt: any) => {
    evt.preventDefault()
    onSubmit({...localValues, firstName: localValues.firstName.trim(), lastName: localValues.lastName.trim(), birthName: localValues.birthName.trim()})
  }

  const isPostierValid = React.useMemo(() => {
   return !postier || (postier && postierId !== '')
  }, [postierId, postier])

  return (
    <form onSubmit={handleSubmit} noValidate={true}>
      <Grid container={true}>
        <Grid item={true} container={true} direction="column" md={12}>
          <Box pt={4} pl={2}>
            <Typography variant="h5"><strong>Candidat</strong></Typography>
          </Box>

          <FormControl>
            <RadioGroup row={true} className={classes.radio} defaultValue={Civility.F}>
              <FormControlLabel
                label="Madame"
                value={Civility.F}
                onClick={() => handleRadioInputChange('gender', Civility.F)}
                control={<Radio color="primary" checked={gender === Civility.F} name="gender"/>}
              />
              <FormControlLabel
                label="Monsieur"
                value={Civility.M}
                onClick={() => handleRadioInputChange('gender', Civility.M)}
                control={<Radio color="primary" checked={gender === Civility.M} name="gender"/>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item={true} container={true} direction="column" md={6}>
          <FormControl className={classes.input}>
            <TextField id="firstName"
                       name="firstName"
                       label="Prénom*"
                       value={firstName}
                       helperText={touched.firstName ? errors.firstName : ''}
                       error={touched.firstName && Boolean(errors.firstName)}
                       onChange={change.bind(null, 'firstName')}
            />
          </FormControl>

          <FormControl className={classes.input}>
            <TextField id="lastName"
                       name="lastName"
                       label="Nom*"
                       value={lastName}
                       helperText={touched.lastName ? errors.lastName : ''}
                       error={touched.lastName && Boolean(errors.lastName)}
                       onChange={change.bind(null, 'lastName')}
            />
          </FormControl>

          <FormControl className={classes.input}>
            <TextField id="birthName"
                       name="birthName"
                       label="Nom de naissance (si différent)"
                       value={birthName}
                       helperText={touched.birthName ? errors.birthName : ''}
                       error={touched.birthName && Boolean(errors.birthName)}
                       onChange={change.bind(null, 'birthName')}
            />
          </FormControl>

          <FormControl className={classes.input} error={touched.studyLevel && Boolean(errors.studyLevel)}>
            <InputLabel id="studyLevel-label">Niveau d'étude*</InputLabel>
            <Select id="studyLevel"
                    name="studyLevel"
                    labelId="studyLevel-label"
                    value={studyLevel}

                    onChange={change.bind(null, 'studyLevel')}>
              {
                studyLevelList.map((studyLevel, index) =>
                  <MenuItem value={studyLevel} key={index}>{studyLevel}</MenuItem>)
              }
            </Select>
            {
              touched.studyLevel && Boolean(errors.studyLevel) &&
              <FormHelperText>{touched.studyLevel ? errors.studyLevel : ''}</FormHelperText>
            }
          </FormControl>
          <FormControl className={classes.input} error={touched.origin && Boolean(errors.origin)}>
            <InputLabel id="origin-label">Origine de votre démarche*</InputLabel>
            <Select id="origin"
                    name="origin"
                    labelId="origin-label"
                    value={origin}

                    onChange={change.bind(null, 'origin')}>
              {
                originList.map((origin, index) =>
                  <MenuItem value={origin} key={index}>{origin}</MenuItem>)
              }
            </Select>
            {
              touched.origin && Boolean(errors.origin) &&
              <FormHelperText>{touched.origin ? errors.origin : ''}</FormHelperText>
            }
          </FormControl>
          <FormControl className={classes.input}>
              <TextField id="formationId"
                        name="formationId"
                        label="Identifiant de formation (optionnel)"
                        value={formationId}
                        helperText={touched.formationId ? errors.formationId : ''}
                        error={touched.formationId && Boolean(errors.formationId)}
                        onChange={change.bind(null, 'formationId')}
              />
            </FormControl>
        </Grid>
        <Grid item={true} container={true} direction="column" md={6}>
          <FormControl className={classes.datePicker} error={touched.birthday && Boolean(errors.birthday)}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableFuture={true}
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                autoOk={true}
                id="birthday"
                name="birthday"
                label="Date de naissance*"
                value={birthday || null}
                openTo={'year'}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'Changer la date de naissance',
                }}
              />
            </MuiPickersUtilsProvider>
            {
              touched.birthday && <FormHelperText>{errors.birthday}</FormHelperText>
            }
          </FormControl>
          <FormControl className={classes.input} error={touched.birthContinent && Boolean(errors.birthContinent)}>
            <InputLabel id="birthContinent-label">Zone géographique de naissance*</InputLabel>
            <Select id="birthContinent"
                    name="birthContinent"
                    labelId="birthContinent-label"
                    value={birthContinent}

                    onChange={change.bind(null, 'birthContinent')}>
              {
                continentList.map((continent, index) =>
                  <MenuItem value={continent} key={index}>{continent}</MenuItem>)
              }
            </Select>
            {
              touched.birthContinent && Boolean(errors.birthContinent) &&
              <FormHelperText>{touched.birthContinent ? errors.birthContinent : ''}</FormHelperText>
            }
          </FormControl>
          <FormControl className={classes.input}>
            <TextField id="birthCity"
                       name="birthCity"
                       label="Ville de naissance*"
                       value={birthCity}
                       helperText={touched.birthCity ? errors.birthCity : ''}
                       error={touched.birthCity && Boolean(errors.birthCity)}
                       onChange={change.bind(null, 'birthCity')}
            />
          </FormControl>
          <FormControl className={classes.input}>
            <TextField id="birthZipCode"
                       name="birthZipCode"
                       label="Code postal du lieu de naissance*"
                       value={birthZipCode}
                       helperText={touched.birthZipCode ? errors.birthZipCode : ''}
                       error={touched.birthZipCode && Boolean(errors.birthZipCode)}
                       onChange={change.bind(null, 'birthZipCode')}
            />
          </FormControl>
          <FormControl className={classes.input} error={touched.funder && Boolean(errors.funder)}>
            <InputLabel id="funder-label">Financeur*</InputLabel>
            <Select id="funder"
                    name="funder"
                    labelId="funder-label"
                    value={funder}

                    onChange={change.bind(null, 'funder')}>
              {
                funderList.map((funder, index) =>
                  <MenuItem value={funder} key={index}>{funder}</MenuItem>)
              }
            </Select>
            {
              touched.funder && Boolean(errors.funder) &&
              <FormHelperText>{touched.funder ? errors.funder : ''}</FormHelperText>
            }
          </FormControl>
          <FormControlLabel
            control={<Checkbox checked={postier} onChange={handlePostier} name="postier" />}
            label="Je suis salarié(e) du groupe La Poste"
          />
          {postier &&
          <>
            <FormControl className={classes.input}>
              <TextField id="postierId"
                        name="postierId"
                        label="Identifiant postier*"
                        value={postierId}
                        helperText={touched.postierId ? errors.postierId : ''}
                        error={!isPostierValid}
                        onChange={change.bind(null, 'postierId')}
              />
              {!isPostierValid && 
              <FormHelperText color="error">Veuillez renseigner votre identifiant La poste</FormHelperText>
              }
            </FormControl>
          </>
          }
        </Grid>

        <Grid item={true} container={true} direction="column" md={12}>
          <Box pt={4} pl={2}>
            <Typography variant="h5"><strong>Contact</strong></Typography>
          </Box>
        </Grid>

        <Grid item={true} container={true} direction="column" md={6}>
          <FormControl className={classes.input}>
            <TextField id="postalAddress"
                       name="postalAddress"
                       label="Adresse postale*"
                       value={postalAddress}
                       helperText={touched.postalAddress ? errors.postalAddress : ''}
                       error={touched.postalAddress && Boolean(errors.postalAddress)}
                       onChange={change.bind(null, 'postalAddress')}
            />
          </FormControl>
          <FormControl className={classes.input}>
            <TextField id="city"
                       name="city"
                       label="Ville*"
                       value={city}
                       helperText={touched.city ? errors.city : ''}
                       error={touched.postalAddress && Boolean(errors.city)}
                       onChange={change.bind(null, 'city')}
            />
          </FormControl>
          <FormControl className={classes.input}>
            <InputMask
              mask="09 99 99 99 99"
              id="cardNumber"
              value={phone}
              onChange={change.bind(null, 'phone')}
            >
              {
                (inputProps: any) => (
                  <TextField id="phone"
                             name="phone"
                             label="Téléphone*"
                             value={phone}
                             {...inputProps}
                             InputProps={{
                               endAdornment: (
                                 <InputAdornment position="end">
                                   <Phone color="action"/>
                                 </InputAdornment>),
                             }}
                             helperText={touched.phone && errors.phone ? 'Numéro de téléphone invalide' : ''}
                             error={touched.phone && Boolean(errors.phone)}
                  />
                )
              }

            </InputMask>
          </FormControl>
        </Grid>
        <Grid item={true} container={true} direction="column" md={6}>
        <FormControl className={classes.input}>
            <TextField id="zipCode"
                       name="zipCode"
                       label="Code postal*"
                       value={zipCode}
                       helperText={touched.zipCode ? errors.zipCode : ''}
                       error={touched.zipCode && Boolean(errors.zipCode)}
                       onChange={change.bind(null, 'zipCode')}
            />
          </FormControl>
          <FormControl className={classes.input}>
            <TextField id="email"
                       name="email"
                       label="Adresse e-mail"
                       type="email"
                       value={email}
                       InputProps={{
                         endAdornment: (
                           <InputAdornment position="end">
                             <Email color="action"/>
                           </InputAdornment>),
                       }}
                       helperText={touched.email ? errors.email : ''}
                       error={touched.email && Boolean(errors.email)}
                       onChange={change.bind(null, 'email')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center">
        <ButtonAsync type="submit" color="primary" variant="contained" disabled={submissionDisabled || !isValid || !isPostierValid} waiting={submissionDisabled}>
          Valider mes informations
        </ButtonAsync >
      </Box>
    </form>
  )
}

export default Form

import moment from 'moment'
import { API_PAYMENT_ENDPOINT, API_RESERVATIONS_ENDPOINT, API_SESSIONS_ENDPOINT, API_SITES_ENDPOINT, STRIPE_PRODUCT_ID } from '../config/app.settings'

export interface IApiSite {
  id: number,
  nomSite: string,
  accessibilitePmr: boolean,
  numVoie: string,
  nomVoie: string,
  lignecomp1: string,
  lignecomp2: string,
  lignecomp3: string,
  codepostal: string,
  localite: string,
  numeroSalle: number | null,
  descriptionSalle: string | null,
  latitude: number,
  longitude: number,
  dateOuverture: Date,
  dateFermeture: Date | null,
  capacite: number,
  idProchaineSessionDisponible: number
}

export interface IApiSession {
  id: number,
  idSite: number,
  dateDebut: string,
  dateFin: string,
  nombrePlacesTotal: number,
  nombrePlacesRestant: number,
  dateLimiteInscription: string,
  idPrivatisation: string | null,
  prixTTC: number,
  typeActivite: string
}

type IServiceResponse<T> = IServiceSuccessResponse<T> | IServiceFailResponse

interface IServiceSuccessResponse<T> {
  data: T
  success: true
}

interface IServiceFailResponse {
  data: string
  success: false
  status: number | null
}

export enum Civility {
  M = 'M',
  F = 'F'
}

export interface IUserData {
  gender: Civility
  lastName: string
  firstName: string
  birthday?: Date
  birthName: string
  birthContinent: string
  birthZipCode: string
  birthCity: string
  phone: string
  email: string
  studyLevel: string
  origin: string
  funder: string
  postalAddress: string
  city: string
  zipCode: string
  postierId: string
  formationId: string
}

interface ISku {
  id: string,
  object: 'sku',
  active: boolean,
  attributes: {
    [att: string]: string
  },
  created: number,
  currency: string,
  image: string | null,
  inventory: {
    quantity: number,
    type: 'finite' | 'bucket' | 'infinite',
    value: null | string
  },
  livemode: false,
  metadata: { [key: string]: any },
  package_dimensions: null | string,
  price: number,
  product: string,
  updated: number
}

export interface IUserDataApi {
  gender: Civility
  lastName: string
  firstName: string
  birthday: string
  birthName: string
  birthContinent: string
  birthZipCode: string
  birthCity: string
  phone: string
  email: string
  studyLevel: string
  origin: string
  funder: string
  postalAddress: string
  city: string
  zipCode: string
}

type FetchType = (input?: Request | string, init?: RequestInit) => Promise<Response>

export interface ISessionRequestParam {
  dateMin: string
  dateMax: string
  longitude: number
  latitude: number
}

interface ICreateReservationRequestBody {
  sessionId: number,
  candidat: IUserDataApi
}

export const getSiteList = async (): Promise<IServiceResponse<IApiSite[]>> => {
  try {
    const response = await fetch(`${API_SITES_ENDPOINT}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}
export const getSessionList = async (params: ISessionRequestParam): Promise<IServiceResponse<IApiSession[]>> => {
  const parameters = new URLSearchParams(`rayonDeRecherche=50`)
  const parametersKeyList = Object.keys(params)
  parametersKeyList.forEach((key: string) => {
    parameters.set(key, params[key])

  })
  try {
    const response = await fetch(`${API_SESSIONS_ENDPOINT}?${parameters}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}

export const createReservation =
  async (fetchMethod: FetchType, sessionId: number, userData: IUserData): Promise<IServiceResponse<any>> => {
    const body: ICreateReservationRequestBody = {
      sessionId,
      candidat: formatUserForApi(userData),
    }
    try {
      const response = await fetchMethod(
        `${API_RESERVATIONS_ENDPOINT}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {'content-type': 'application/json'},
        },
      )
      if (!response.ok) {
        console.log(response)
        return {success: false, data: response.statusText, status: response.status}
      }
      return {success: true, data: await response.json()}
    } catch (error) {
      return {success: false, data: error, status: null}
    }
  }

export const getSessionPrice = async (fetchMethod: FetchType): Promise<IServiceResponse<ISku>> => {
  try {
    const response = await fetchMethod(`${API_PAYMENT_ENDPOINT}/stripe/product/sku/${STRIPE_PRODUCT_ID}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}

export const checkCodeValidation = async (fetchMethod: FetchType, code: string): Promise<IServiceResponse<any>> => {
  try {
    const response = await fetchMethod(`${API_PAYMENT_ENDPOINT}/stripe/product/coupon/${code}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}

export const createInvoice =
  async (fetchMethod: FetchType, {lastName, email, phone}: IUserData, reservationId): Promise<IServiceResponse<any>> => {
    try {
      const response = await fetchMethod(
        `${API_PAYMENT_ENDPOINT}/stripe/invoice`,
        {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify({
            customer: {
              name: lastName,
              email,
              phone,
            },
            metadata: {
              reservationId,
            },
            skus: [
              STRIPE_PRODUCT_ID,
            ],
          }),
        },
      )
      if (!response.ok) {
        return {success: false, data: response.statusText, status: response.status}
      }
      return {success: true, data: await response.json()}
    } catch (error) {
      return {success: false, data: error, status: null}
    }
  }

export const createSetupIntent =
  async (fetchMethod: FetchType, invoiceId: string, {lastName, email, phone}: IUserData): Promise<IServiceResponse<any>> => {
    try {
      const response = await fetchMethod(
        `${API_PAYMENT_ENDPOINT}/stripe/invoice/${invoiceId}/intent`,
        {
          headers: {'content-type': 'application/json'},
          method: 'POST',
          body: JSON.stringify({
            customer: {
              name: lastName,
              email,
              phone,
            },
            invoiceId,
          }),
        },
      )
      if (!response.ok) {
        return {success: false, data: response.statusText, status: response.status}
      }
      return {success: true, data: await response.json()}
    } catch (error) {
      return {success: false, data: error, status: null}
    }

  }

export const consumeCodeForPayment =
  async (fetchMethod: FetchType, invoiceId: string, {lastName, email, phone}: IUserData, coupon: string): Promise<IServiceResponse<any>> => {
    try {
      const response = await fetchMethod(
        `${API_PAYMENT_ENDPOINT}/stripe/invoice/${invoiceId}/finalize`,
        {
          headers: {'content-type': 'application/json'},
          method: 'POST',
          body: JSON.stringify({
            customer: {
              name: lastName,
              email,
              phone,
            },
            coupon,
          }),
        },
      )
      if (!response.ok) {
        return {success: false, data: response.statusText, status: response.status}
      }
      return {success: true, data: await response.json()}
    } catch (error) {
      return {success: false, data: error, status: null}
    }
  }

const formatUserForApi = (userData: IUserData): IUserDataApi => {
  return {
    ...userData,
    birthday: moment(userData.birthday).format('YYYY-MM-DD'),
  }
}

export const getSession = async (id: string): Promise<IServiceResponse<IApiSession>> => {
  try {
    const response = await fetch(`${API_SESSIONS_ENDPOINT}/${id}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}
export const getReservation = async (id: string): Promise<IServiceResponse<IApiSession>> => {
  try {
    const response = await fetch(`${API_RESERVATIONS_ENDPOINT}/${id}`)
    if (!response.ok) {
      return {success: false, data: response.statusText, status: response.status}
    }
    return {success: true, data: await response.json()}
  } catch (error) {
    return {success: false, data: error, status: null}
  }
}

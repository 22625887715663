import * as React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import Section, {
  SectionTitle,
  SectionSubTitle,
} from "../../components/Section";
import Skills, { SkillsContainer } from "../../components/Skills";
import Graph from "../../components/Graph";
import QuizList from "./QuizList";

import skills1 from "../../assets/images/ic-outils.svg";
import skills2 from "../../assets/images/ic-exploiter-info.svg";
import skills3 from "../../assets/images/ic-collaborer.svg";
import skills4 from "../../assets/images/ic-securite.svg";

import digi from "../../assets/images/ic-logo-digischool.png";
import afpa from "../../assets/images/ic-logo-afpa.svg";
import laposte from "../../assets/images/la-poste-logo.svg";
import {
  PAPER_POSTE_CONTENT,
  PAPER_DIGI_CONTENT,
  PAPER_AFPA_CONTENT,
} from "../LandingPage/landing.contant";
import { usePaperAboutStyles } from "../LandingPage/components/PaperAbout";

const Partners = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;

  @media screen and (max-width: 640px) {
    margin-top: 8px;
    flex-direction: column;
    align-items: center;
  }
`;

const Partner = styled.div`
  margin: 8px 30px 8px 30px;
`;

export default () => {
  const classes = usePaperAboutStyles();
  return (
    <Fragment>
      <Section>
        <SectionTitle>Qu'est-ce que le CléA Numérique ?</SectionTitle>
        <SectionSubTitle>
          Le CléA Numérique est une certification délivrée par Certif Pro* qui
          s’appuie sur un référentiel de connaissances et de compétences
          relatives à l’usage du numérique au sein d’un environnement de
          travail.
        </SectionSubTitle>
        <p>
          La certification CléA Numérique évalue 4 domaines de compétences :
        </p>
        <SkillsContainer>
          <Skills image={skills1} alt="identifier">
            Identifier son environnement et utiliser les outils <br /> associés
          </Skills>
          <Skills image={skills2} alt="acquérir">
            Acquérir et exploiter <br />
            de l’information dans <br />
            un environnement <br />
            professionnel numérisé
          </Skills>
          <Skills image={skills3} alt="intégrer">
            Intéragir <br /> en mode collaboratif
          </Skills>
          <Skills image={skills4} alt="appliquer">
            Appliquer les règles et bonnes pratiques de la sécurité numérique
          </Skills>
        </SkillsContainer>
        <p style={{ marginTop: 40 }}>
          La certification CléA Numérique s’adresse à toute personne en activité
          ou en recherche d’emploi souhaitant valoriser auprès des employeurs,
          sa capacité à travailler dans un environnement professionnel impacté
          par les évolutions liées au numérique.
        </p>
      </Section>
      <Section backgroundColor="#F3F3F8">
        <SectionTitle>Notre accompagnement à la certification</SectionTitle>
        <p>
          digiSchool, l'AFPA et La Poste mettent en commun leur savoir-faire
          pour vous accompagner à l'obtention de la certification CléA
          Numérique.
        </p>

        <p>
          Le parcours de formation et de certification, vous permet dans un
          premier temps de déterminer votre profil numérique. Ce test de
          profilage est disponible sur ce site web ou sur l'application mobile :
          superCléA Num.
        </p>

        <p>
          En téléchargeant l'application superCléA Num vous aurez accès à un
          contenu d'entrainement sous forme de leçons et de quiz. Notre objectif
          est de proposer un contenu facile d’accès pour permettre à tous
          d'acquérir les bases et bonnes pratiques liées à l’utilisation du
          numérique dans son quotidien personnel et professionnel.
        </p>

        <p>
          Découvrir l'application superCléA Num est un premier pas vers la
          certification CléA Numérique !
        </p>

        <p>
          Suivant votre profil, l'AFPA pourra ensuite vous proposer un parcours
          de formation en présentiel et/ou à distance afin de vous préparer au
          mieux à la certification CléA Numérique.
        </p>

        <p>
          La certification est une épreuve d’une heure et demi, passée dans les
          centres d’examen La Poste. L'épreuve se déroule sur tablette numérique
          et a été construite de manière à valider la maîtrise des quatre
          compétences clés du référentiel CléA Numérique.
        </p>
        <SectionTitle>
          Êtes-vous un utilisateur du numérique occasionnel, régulier ou
          connecté ?
        </SectionTitle>
        <p>Pour le savoir, déterminez votre profil grâce au test.</p>
        <p>
          Ce test est composé de 40 questions, mixant questions
          d’auto-évaluation de sa pratique, de connaissances, et de recherches
          d’informations.
        </p>
        <p>
          A la fin, vous aurez accès à votre résultat, ainsi qu’aux détails de
          vos réponses et aux explications des questions.{" "}
        </p>

        <Section>
          <QuizList />
        </Section>
        <SectionTitle>
          Pourquoi se former et passer la certification CléA Numérique ?
        </SectionTitle>
        <p>
          Dans notre société, la maîtrise des usages du numérique est devenue
          essentielle pour réaliser les actions les plus simples : prendre
          rendez-vous chez le médecin, payer ses impôts en ligne, acheter un
          billet de train…
        </p>
        <p>
          Le développement du numérique est encore plus accentué dans le milieu
          professionnel. Maîtriser l’utilisation du numérique est maintenant un
          facteur clé de succès dans la recherche d’emploi et l’insertion
          professionnelle.
        </p>
        <p>
          Ces évolutions rapides font qu’aujourd’hui 40 % de la population est
          en difficulté face au numérique dont :
        </p>
        <Graph />
      </Section>
      <Section backgroundColor="#e1f1cb">
        <SectionTitle>A propos de digiSchool</SectionTitle>
        <p>{PAPER_DIGI_CONTENT}</p>
        <SectionTitle>A propos de l’Afpa</SectionTitle>
        <p
          className={classes.paperContent}
          dangerouslySetInnerHTML={{ __html: PAPER_AFPA_CONTENT }}
        />
        <SectionTitle>A propos de La Poste</SectionTitle>
        <p>{PAPER_POSTE_CONTENT}</p>
        <Partners>
          <Partner>
            <img src={digi} alt="digischool" />
          </Partner>
          <Partner>
            <img src={afpa} alt="afpa" />
          </Partner>
          <Partner>
            <img src={laposte} alt="La poste" />
          </Partner>
        </Partners>
      </Section>
    </Fragment>
  );
};

import React from 'react'

import {TextField } from '@material-ui/core'
import StripeInput from './StripeInput'

type IStripeElementWrapperProps = any

const StripeElementWrapper = ({component, label, InputProps, ...otherProps}: IStripeElementWrapperProps) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleElementChange = ({ error }: any) => {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
    }
  }

  const hasError = errorMessage !== null;

  return (
    <TextField
      fullWidth={true}
      label={label}
      error={hasError}
      helperText={hasError ? errorMessage || "Invalid" : ""}
      onChange={handleElementChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component
        },
        inputComponent: StripeInput
      }}
      {...otherProps}
    />
  );
}

export default StripeElementWrapper

// Material ui
import { makeStyles, Theme, createStyles } from "@material-ui/core";

// Assets
import backgroundHeader from "../assets/vague.svg";

export const useLandingHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
      container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      root: {
        backgroundImage: `url(${backgroundHeader})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        width: "100%",
      },
      storeImg: {
        [theme.breakpoints.down("md")]: {
          width: 142,
          margin: "2px auto 8px auto",
        },
        margin: theme.spacing(2, 4, 0, 0),
      },
      typography: {
        paddingTop: theme.spacing(13),
        [theme.breakpoints.down("md")]: {
          paddingTop: theme.spacing(2),
          fontSize: 36,
        },
        fontWeight: "bold",
        fontSize: 45,
        maxWidth: 460,
      },
    },
  ),
);

// Material ui
import { makeStyles, Theme, createStyles } from '@material-ui/core'

/**
 * Form styles
 */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      margin: theme.spacing(0, 2, 5, 2),
    },
    radio: {
      margin: theme.spacing(3, 0, 0, 2),
    },
    datePicker: {
      margin: theme.spacing(-2, 2, 4, 2),
    }
  }),
);

export const ROUTE_HOMEPAGE = "/";
export const ROUTE_QUIZ = "/quiz/:id";
export const ROUTE_BOOKING = "/reservation";
export const ROUTE_PROFILE = "/profil";
export const ROUTE_BOOKING_STEP = '/reservation/:step'
export const ROUTE_BOOKING_CONFIRMATION = '/reservation/confirmation'
export const ROUTE_LANDING = '/applications'

export const LoggedRouteList = [
  '/reservation/recapitulatif',
  '/reservation/compte',
  '/reservation/payment',
  '/reservation/confirmation'
]

// Core
import React from 'react'

// Material ui
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { ButtonProps, Button, CircularProgress } from '@material-ui/core'

/**
 * ButtonAsync component props
 */
interface IButtonAsyncProps extends ButtonProps{
  waiting?: boolean
}

/**
 * Form styles
 */
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      marginRight: theme.spacing(2)
    }
  }),
);

/**
 * ButtonAsync component
 */
const ButtonAsync = ({waiting, children, ...otherProps}: IButtonAsyncProps) => {
  /** Styles */
  const classes = useStyles()

  return (
    <Button {...otherProps} disabled={waiting || otherProps.disabled}>
      {waiting && <CircularProgress size={20} className={classes.loader} />} {children}
    </Button>
  )

}

export default ButtonAsync

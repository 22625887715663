// Core
import React from 'react'

// Material ui
import { Box, Grid, makeStyles, createStyles, Theme, CircularProgress, Typography } from '@material-ui/core'

// Store
import { useDispatch, useSelector } from 'react-redux'
import {
  setReservationCurrentStep,
  setSiteList,
  setSite,
  setSession,
  setLastSearchResult, resetSite, resetSession, setReservationSearch,
} from '../../store/reservation/actions'
import { IAppState } from '../../store'
import { ISearch } from '../../store/reservation'

// Components
import { stepList } from './index'
import Modal from '../../components/Modal'

// Route
import { RouteComponentProps } from 'react-router'

// dsk-lib
import {
  PlaceBlock,
  BlockSessionReservation,
  ReservationTileList,
  ReservationTile,
  SearchBar,
} from '@dsk-lib/reservation'
import { IFeature } from '@dsk-lib/reservation/dist/services/geocode'
import { ISearchBarProps } from '@dsk-lib/reservation/dist/components/SearchBar'
import { useUserAuthentication } from '@dsk-lib/user'

// Core
import { getSessionListResult, getAllSite } from './search.core'
import {
  formatSearchResult,
  ISpaSiteWithSessionsGroupByDay,
  ISpaSession, ISpaSite,
} from '../../services/api.formater'
import { ReservationStep } from './reservation.constant'
import { MIN_DATE } from '../../config/app.settings'

/**
 * Search component props
 */
interface ISearchProps extends RouteComponentProps {
}

/**
 * Seach state
 */
interface ISearchState {
  selectedSiteIndex?: number
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  sessionReservation: {
    position: 'absolute',
    top: 0,
    margin: 'auto',
    width: '100%',
  },
  site: {
    maxWidth: 850,
  },
  placeBlockWrapper: {
    overflow: 'auto',
    marginTop: theme.spacing(2),
  },
  placeBlockWrapperDisabled: {
    filter: 'blur(5px)',
    overflow: 'hidden',
  },
}))

/** Search bar with store */
const SearchBarWithStore = (props: ISearchBarProps) => {
  const initialSearch = useSelector((state: IAppState) => state.reservation.search)

  const newProps = initialSearch
    ? {...props, initialValues: {location: initialSearch.location, to: initialSearch.to, from: initialSearch.from}}
    : props
  return <SearchBar {...newProps} minDate={MIN_DATE}/>
}

/** Constants */
const listingMaxHeight = 629

/** Varianbles */
let firstSearch: boolean = true

/**
 * Get selected site from localstorage data
 * @param searchResult
 * @param site
 * @param selectedSiteIndex
 */
const getSelectedSite = (searchResult: ISpaSiteWithSessionsGroupByDay[], site?: ISpaSite, selectedSiteIndex?: number) => {
  if (site && searchResult.length) {
    return searchResult.find((result) => result.site.id === site.id)
  } else {
    return typeof selectedSiteIndex === 'number' && searchResult && searchResult[selectedSiteIndex]
  }
}

/**
 * Search component
 */
const Search = ({history}: ISearchProps) => {
  /** Styles */
  const classes = useStyles()
  /** Store */
  const dispatch = useDispatch()
  const {siteList, search, lastSearchResult, site, session} =
    useSelector((state: IAppState) => state.reservation)

  /** Authentication hook*/
  const {authentication} = useUserAuthentication()

  /** State */
  const [searchState, setSearchState] = React.useState<ISearchState>({})
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  /** Constants */
  const nextStep = ReservationStep.Recap
  const selectedSite = getSelectedSite(lastSearchResult, site, searchState.selectedSiteIndex)

  /**
   * Get session
   */
  const getSession = React.useCallback(async (newSearch: ISearch) => {
    const sessionList = await getSessionListResult(newSearch)
    dispatch(setLastSearchResult(formatSearchResult(siteList, sessionList)))
    setIsLoading(false)
  }, [search, siteList])

  /**
   * Search effect
   */
  React.useEffect(() => {
    if(!search) {
      history.push('/reservation')
      return
    } else if (firstSearch && lastSearchResult.length) {
      setIsLoading(false)
    } else if (siteList.length && search) {
      getSession(search)
      firstSearch = false
    }
  }, [siteList.length, search])

  /** Init effect get all sites */
  React.useEffect(() => {
    if (!siteList.length) {
      getAllSite().then((allSite) => {
        dispatch(setSiteList(allSite))
      })
    }
  }, [siteList.length, dispatch])

  /**
   * Handle place block selection
   * @param selectedSiteIndex
   */
  const handlePlaceBlockClick = (selectedSiteIndex: number) => {
    dispatch(setSite(lastSearchResult[selectedSiteIndex].site))
    setSearchState({...searchState, selectedSiteIndex})
  }

  /**
   * Handle close reservation element
   */
  const handleCloseSessionReservation = () => {
    setSearchState({...searchState, selectedSiteIndex: undefined})
    dispatch(resetSite())
    dispatch(resetSession())
  }

  /**
   * Handle session selection
   * @param selectedSession
   */
  const handleSessionSelect = (selectedSession: ISpaSession) => {
    dispatch(setSession(selectedSession))

  }

  /**
   * Handle reservation submit
   */
  const handleBookingSubmit = () => {
    if (site && session) {
      dispatch(setReservationCurrentStep(nextStep))
      if (!authentication.isAuthenticated) {
        setModalOpen(true)
      } else {
        history.push(stepList[nextStep].slug)
      }
    }
  }

  /**
   * Handle search submit
   * @param location
   * @param from
   * @param to
   */
  const handleSearchSubmit = (location: IFeature, from: Date, to: Date) => {
    setIsLoading(true)
    firstSearch = false
    dispatch(setReservationSearch({location, from, to}))

    getSession({location, from, to})
  }

  return (
    <>
      <Modal
        title="Connectez-vous"
        open={modalOpen}
        buttonOne="Je me connecte"
        buttonOneTo="/login"
        buttonTwo="Je m'inscris"
        buttonTwoTo="/register"
      >
        <p>
          Pour effectuer cette réservation, vous devez d’abord vous inscrire ou vous
          connecter.
          <br/>
          Ça ne prend que quelques secondes.
        </p>
      </Modal>
      <Box pb={2} className={classes.root}>
        <Grid container={true} spacing={4} justify="center">
          <Grid item={true}>
            <SearchBarWithStore black={true} onSearch={handleSearchSubmit} />
          </Grid>
          {
            isLoading || !lastSearchResult
              ? <Grid item={true} container={true} xs={12} justify="center"><CircularProgress/></Grid>
              : <Grid container={true} item={true} spacing={2}
                      className={
                        `${classes.placeBlockWrapper} ${typeof searchState.selectedSiteIndex !== 'undefined'
                        && classes.placeBlockWrapperDisabled}`}>
                {
                  lastSearchResult.length
                    ? lastSearchResult.map(({site, count}, index) =>
                      <Grid item={true} key={index} xs={6}>
                        <PlaceBlock
                          id={site.id}
                          name={site.name}
                          address={site.address}
                          nbSessions={count}
                          onSelect={() => handlePlaceBlockClick(index)}
                        />
                      </Grid>,
                    )
                    : <Grid item={true} xs={12}><Typography align="center">Aucune session disponible pour votre recherche.</Typography></Grid>
                }
              </Grid>
          }
        </Grid>

        {
          selectedSite && <Box className={classes.sessionReservation}>
              <BlockSessionReservation
                  name={selectedSite.site.name}
                  address={selectedSite.site.address}
                  nbSessions={selectedSite.count}
                  maxHeight={listingMaxHeight}
                  submitDisabled={!session}
                  onClose={handleCloseSessionReservation}
                  onSubmit={handleBookingSubmit}
              >
                <>
                  {
                    selectedSite.sessionsDayList.map((sessionGroup) =>
                      <ReservationTileList key={sessionGroup.day} date={new Date(sessionGroup.day)}>
                        {
                          sessionGroup.sessionList.map((sess) => <ReservationTile
                            onSelect={() => handleSessionSelect(sess)}
                            selected={session && session.id === sess.id}
                            key={sess.id}
                            id={sess.id}
                            nbPlaces={sess.availablePlaces} date={new Date(sess.openingTime)}/>)
                        }
                      </ReservationTileList>,
                    )
                  }
                </>
              </BlockSessionReservation>

          </Box>
        }
      </Box>
    </>
  )

}

export default Search

import * as React from "react";
import styled from "styled-components";

interface IContainerProps {
  width?: number | string;
  height?: number | string;
}

const Container = styled.div`
  width: ${(props: IContainerProps) => props.width};
  height: ${(props: IContainerProps) => props.height};
  position: absolute;
  z-index: -1;
`;

interface IBackgroundProps {
  width?: number | string;
  height?: number | string;
}

export default ({ width, height }: IBackgroundProps) => {
  width = width || "100%";
  height = height || 489;
  return (
    <Container width="100%">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        width={width}
        height={height}
        viewBox="0 0 1440 489"
      >
        <defs>
          <linearGradient
            id="a"
            x1="0%"
            x2="102.392%"
            y1="56.646%"
            y2="44.584%"
          >
            <stop offset="0%" stopColor="#9ED604" />
            <stop offset="100%" stopColor="#28BC6D" />
          </linearGradient>
        </defs>
        <path
          fill="url(#a)"
          d={`M1440 506.67S1359.67 525 1204.67 525 933 456.67 791.33 456.67C649.66 456.67 
          434.67 525 293 525 151.33 525 0 506.67 0 506.67V0h1440v506.67z`}
          transform="translate(0 -36)"
        />
      </svg>
    </Container>
  );
};

// Core
import React from 'react'

const StripeInput = (props: any) => {
  const { component: Component, inputRef, ...other } = props;
  const elementRef = React.useRef<HTMLInputElement>();

  React.useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current && elementRef.current.focus
  }));

  return (
    <Component onReady={element => (elementRef.current = element)} {...other} />
  );
}

export default StripeInput

import { IApiSite, IApiSession } from './api'
import moment from 'moment'

export interface ISpaSite {
  id: number
  name: string
  address: string
  zipcode: string
  city: string
  distance?: number
  coordinates: [number, number]
}

export interface ISpaSession {
  id: number,
  day: string,
  openingTime: string,
  subLimit: string,
  availablePlaces: number,
  price: number,
}

interface IApiSiteWithSessions{
  site: IApiSite
  sessionList: IApiSession[]
}

export interface ISpaSiteWithSessionsGroupByDay{
  site: ISpaSite
  count:number
  sessionsDayList: ISpaSessionsDay[]
}

interface ISpaSessionsDay {
  day: string,
  count:number,
  sessionList: ISpaSession[],
}

interface IApiSessionWithApiSite extends IApiSession{
  site: IApiSite
}

export const formatSearchResult =
  (siteList: IApiSite[], sessionList: IApiSession[]): ISpaSiteWithSessionsGroupByDay[] => {
  const sessionListWithSite = sessionList.map((session) => mergeSiteDataInSession(session, siteList))
  const sessionsGroupedBySiteList = sessionListWithSite.reduce(groupSessionListBySiteId, [])
  return sessionsGroupedBySiteList.map((sessionsGroupedBySite) => {
    const sessionsDayList =  groupSessionByDay(sessionsGroupedBySite.sessionList)
    return {
      site: mapSiteForSpa(sessionsGroupedBySite.site),
      sessionsDayList,
      count: sessionsDayList.reduce((count: number, sessionsDay: ISpaSessionsDay) => count + sessionsDay.count, 0)
    }
  })
}

export const mergeSiteDataInSession = (session: IApiSession, siteList: IApiSite[]): IApiSessionWithApiSite => {
  const site = getSessionSiteFromSiteList(siteList, session)
  if(site) {
    return {...session, site}
  }
  throw new Error(`No site found for session id ${session.id}, with idSite ${session.idSite}` )
}

export const groupSessionByDay = (sessionListInput: IApiSession[]): ISpaSessionsDay[] => {
  return sessionListInput.reduce(mergeSessionToSessionsDayGroupList, [])
}

const mergeSessionToSessionsDayGroupList = (sessionsGroupList: ISpaSessionsDay[], session: IApiSession): ISpaSessionsDay[]  => {
  const startOfDay =  moment(session.dateDebut).startOf('day').format();
  const dayGroupListIndex = sessionsGroupList.findIndex((sessionListGroup) => sessionListGroup.day === startOfDay)
  if(dayGroupListIndex === -1) {
    return addGroupWithOneSessionToSessionGroupList(sessionsGroupList, session, startOfDay)
  }
  return addSessionToSessionsGroupListIndex(sessionsGroupList, dayGroupListIndex, session)
}

const addGroupWithOneSessionToSessionGroupList =
  (sessionsGroupList: ISpaSessionsDay[], session: IApiSession, day: string): ISpaSessionsDay[] => {
  const sessionList = [mapSessionForSpa(session)]
    return [...sessionsGroupList, {day, sessionList, count:sessionList.length}]
  }

const addSessionToSessionsGroupListIndex =
  (sessionsGroupList: ISpaSessionsDay[], index: number, session: IApiSession): ISpaSessionsDay[] => {
    const newSessionsGroupList = [...sessionsGroupList]
    const {sessionList, day} = sessionsGroupList[index]
    const concatSessionList = [...sessionList, mapSessionForSpa(session)]
    newSessionsGroupList.splice(index, 1, {day, sessionList: concatSessionList, count:concatSessionList.length})

    return newSessionsGroupList
  }

export const mapSessionForSpa = (session: IApiSession): ISpaSession => {
  return {
    id: session.id,
    day: moment(session.dateDebut).format('LLL'),
    openingTime: session.dateDebut,
    subLimit: session.dateLimiteInscription,
    availablePlaces: session.nombrePlacesRestant,
    price: session.prixTTC,
  }
}

export const mapSiteForSpa = (site: IApiSite): ISpaSite => {
  return {
    id: site.id,
    name: site.nomSite,
    zipcode: site.codepostal,
    city: site.localite,
    address: getAddressFromSite(site),
    coordinates: [site.latitude, site.longitude],
  }
}

export const groupSessionListBySiteId = (siteSessionList: IApiSiteWithSessions[], session: IApiSessionWithApiSite): IApiSiteWithSessions[]  => {
  const siteSessionIndex = siteSessionList.findIndex((siteSession) => siteSession.site.id === session.idSite)
  if(siteSessionIndex === -1) {
    return [...siteSessionList, {site: session.site, sessionList: [session]}]
  } else {
    const newSiteSessionList = [...siteSessionList]
    const {sessionList, site} = siteSessionList[siteSessionIndex]
    newSiteSessionList.splice(siteSessionIndex, 1, {site, sessionList: [...sessionList, session]})

    return newSiteSessionList
  }
}

export const getSessionSiteFromSiteList = (siteList: IApiSite[], session: IApiSession): IApiSite | undefined =>
  siteList.find((site) => site.id === session.idSite)

export const getAddressFromSite = (site: IApiSite): string =>
  `${site.numVoie || ''} ${site.nomVoie} - ${site.localite}`

// Material ui
import { makeStyles, Theme, createStyles } from '@material-ui/core'

// Assets
import bgHeader from './assets/img/bg-header.svg'
import icLaPoste from './assets/img/logo-laposte.svg'

export const useBookingPageStyle = makeStyles((theme: Theme) => createStyles({
  container: {
    maxWidth: 1024,
    margin: 'auto',
  },
  searchBarWrapper: {
    maxWidth: 790,
    margin: `-${theme.spacing(5)}px auto 0 auto`,
  },
  illustrationsWrapper: {
    maxWidth: 790,
    margin: '24px auto',
  },
  illustrationParagraph: {
    fontSize: 14,
  },
  skillTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  explanationWrapper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
    backgroundColor: '#F3F3F8',
    backgroundImage: `url(${bgHeader})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center',
  },
  explanationIllus: {
    margin: '24px auto 0 auto',
    display: 'block',
  },
  paperTitle: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  paperDescription: {
    fontSize: 14,
    lineHeight: 1.14,
  },
  paper: {
    maxWidth: 180,
    padding: theme.spacing(3, 1, 2),
    borderRadius: 6,
    minHeight:180,
    marginBottom: theme.spacing(3),
  },
  paperImage: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
  paperWrapper: {
    maxWidth: 790 + 2 * 16,
    margin: `-120px auto 0 auto`,
    padding: theme.spacing(2)
  },
  gridItem: {
    margin: 'auto',
  },
  icLaPoste: {
    display: 'inline-block',
    background: `url(${icLaPoste}) no-repeat center`,
    backgroundSize: 'cover',
    width: 23,
    height: 18,
  }
}))

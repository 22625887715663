import { IApiSession, IApiSite, getSiteList, getSessionList, ISessionRequestParam } from '../../services/api'
import { ISearch } from '../../store/reservation'
import moment from 'moment'

let localSiteList: IApiSite[]

export const getSessionListResult = async (search: ISearch): Promise<IApiSession[]> => {
  const [long, lat] = search.location.geometry.coordinates
  const {from, to} = search
  const realFrom = moment(from).diff(moment(), 'seconds') < 1 ? moment().add(1, 'minutes') : moment(from)
  const params: ISessionRequestParam = {
    latitude: lat,
    longitude: long,
    dateMin: moment(realFrom).format(),
    dateMax: moment(to).format(),
  }
  const sessionListResponse = await getSessionList(params)

  if (sessionListResponse.success) {
    return sessionListResponse.data
  } else {
    throw new Error('Fail get session result')
  }
}

export const getAllSite = async (): Promise<IApiSite[]> => {
  if (localSiteList) {
    return localSiteList
  }
  const siteListResponse = await getSiteList()
  if (!siteListResponse.success) {
    throw new Error(siteListResponse.data)
  }

  if (siteListResponse.success) {
    localSiteList = siteListResponse.data
  }

  return localSiteList
}

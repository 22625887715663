// React
import React from "react";

// Components
import Header from "./components/header";
import { Grid, Typography } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import CardDescription from "./components/CardDescription";

// @dsk-lib
import { EchoTitle } from "@dsk-lib/reservation/dist";

// Assets
import FreeImg from "./assets/ic-free.svg";
import DeviceImg from "./assets/ic-devices.svg";
import ContentImg from "./assets/ic-content.svg";
import PictoImg from "./assets/ic-plus.svg";
import AppStore from "./assets/app-store.svg";
import PlayStore from "./assets/google-play-badge.svg";
import PaperImg from "./assets/t-moignages.svg";
import PaperImgMobile from "./assets/temoignages-mobile.svg";

// Styles
import { useLandingPageStyles } from "./landingPage.style";
import clsx from "clsx";
import PaperAbout from "./components/PaperAbout";

// Constants
import { PAPER_CONTENT_LIST } from "./landing.contant";
import Footer from "../../components/Footer";

/**
 * Landing page component
 */
const LandingPage = (props) => {

  const classes = useLandingPageStyles();

  return (
    <>
      <Grid container={true} component="main" className={classes.root}>
        <Header/>
        <Grid item={true} container={true} justify="center">
          <Grid item={true} xs={12} md={'auto'}>
            <CardDescription img={FreeImg} text={<>100% <br/>gratuite</>}/>
          </Grid>
          <Grid item={true} xs={12} md={'auto'}>
            <CardDescription img={DeviceImg}
                             text={<>Accessible sur<br/> smartphone et tablette<br/>iOS et android</>}/>
          </Grid>
          <Grid item={true} xs={12} md={'auto'}>
            <CardDescription
              img={ContentImg}
              text={<>Contenu simple et ludique !<br/><span>+ de 500 questions</span></>}
            />
          </Grid>
        </Grid>
        <Grid item={true} container={true}>
          <Grid item={true} xs={12}>
            <br/>
            <EchoTitle echo={isWidthDown("md", props.width) ? "" : "Pourquoi télécharger superCléA Num ?"}
                       underline={true}>
              <span className={classes.echoTypo}>Pourquoi télécharger superCléA Num ?</span>
            </EchoTitle>
          </Grid>
        </Grid>
        <Grid item={true} container={true} justify="center" className={classes.downloadDescription}>
          <Grid item={true}>
            <div className={clsx(classes.illustration, classes.illustrationSrcSet)}/>
          </Grid>
          <Grid item={true}>
            <div className={classes.description}>
              <Typography gutterBottom={true}>L'application s'adresse à toute personne souhaitant <strong>améliorer
                son
                utilisation des outils et services mis à sa disposition grâce au numérique.</strong></Typography>
              <br/>
              <Typography>Sous forme de leçons et de quiz, le contenu de l'application vous permettra de rapidement
                améliorer vos connaissances pour :</Typography>
              <br/>
              <ul>
                <Typography component="li"><img src={PictoImg} alt=""/> acquérir des réflexes afin de sécuriser votre
                  navigation sur internet, et vos données personnelles,</Typography>
                <Typography component="li"><img src={PictoImg} alt=""/> mieux maîtriser votre identité
                  numérique,</Typography>
                <Typography component="li"><img src={PictoImg} alt=""/> découvrir des outils pour saisir toutes les
                  opportunités qu'offre le numérique !</Typography>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid item={true} container={true} className={classes.banner} justify="center" spacing={0}>
          <Grid item={true} >
            <Typography variant="h5" className={classes.bannerTypo}>
              <span className={classes.bannerTypo1}>Pour télécharger et découvrir l’application,</span>
              <span className={classes.bannerTypo2}>rendez-vous sur</span>
            </Typography>
            <Grid container={true} className={classes.storeImgContainer}>
              <Grid item={true}>
                <a href="https://apps.apple.com/fr/app/supercl%C3%A9a-num/id1490131484" target="_blank"
                   rel="noopener noreferrer">
                  <img src={AppStore} className={classes.storeImg} alt=""/>
                </a>
              </Grid>
              <Grid item={true}>
                <a href="https://play.google.com/store/apps/details?id=com.digischool.clea" target="_blank"
                   rel="noopener noreferrer">
                  <img src={PlayStore} className={classes.storeImg} alt=""/>
                </a>
              </Grid>
              <Grid item={true} xs={12}>
                <div className={classes.space}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} md={12} lg={"auto"}>
            <div className={classes.bannerIllustrationWrapper}>
              <div className={classes.bannerIllustration}/>
            </div>
          </Grid>
        </Grid>
        <Grid item={true}>
          <div className={classes.temoignage}>
            <EchoTitle echo={isWidthDown("md", props.width) ? "" : "Témoignages"} underline={true}><span
              className={classes.echoTypo}>Témoignages</span></EchoTitle>
            <div className={classes.paperImg}>
              <img src={isWidthDown("md", props.width) ? PaperImgMobile : PaperImg} alt=""/>
            </div>
          </div>
          <div className={classes.temoignage}>
            <EchoTitle echo={isWidthDown("md", props.width) ? "" : "À propos de digiSchool"} underline={true}><span
              className={classes.echoTypo}>À propos</span></EchoTitle>
            <Grid container={true} className={classes.paperGroup} justify="center" spacing={4}>
              {
                PAPER_CONTENT_LIST.map(({ title, content, img, position, isHtml }) =>
                  <Grid item={true} key={title}>
                    <PaperAbout title={title} content={content} img={img} position={position} isHtml={isHtml}/>
                  </Grid>,
                )
              }
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Footer/>
    </>
  );
};

export default withWidth()(LandingPage);

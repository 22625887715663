import * as React from "react";
import styled from "styled-components";
import Wave from "./Wave";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  position: relative;
`;

const Item = styled.a`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 0 25px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 940px) {
    margin: 0 7px;
  }
`;

interface IFooterProps {
  wave?: boolean;
  style?: React.CSSProperties;
}

export default ({ wave, style }: IFooterProps) => {
  return (
    <Container style={style}>
      {wave && <Wave />}
      <Item href="mailto:super-cleanum@digischool.fr">Contact</Item>
      <Item href="https://www.digischool.fr/conditions-generales-d-utilisation.html" target="_blank">CGV / CGU</Item>
      <Item href="https://www.digischool.fr" target="_blank">digiSchool {new Date().getFullYear()}</Item>
    </Container>
  );
};

import * as React from "react";
import Card, { CardContainer } from "../../components/Card";

import quiz1 from "../../assets/images/ic-occasionnel.svg";
import quiz2 from "../../assets/images/ic-regulier.svg";
import quiz3 from "../../assets/images/ic-connecte.svg";

interface IQuizStatus {
  audioActive: boolean;
  autoPlay: boolean;
  correctionMode: boolean;
  correctionStepByStep: boolean;
  currentQuestion: number;
  finish: boolean;
  name: string;
  totalQuestion: number;
  totalScore: number;
  userScore: number;
}

interface IQuizListProps {}
interface IQuizListState {
  quizStatus: IQuizStatus[];
}

export default class QuizList extends React.Component<
  IQuizListProps,
  IQuizListState
> {
  public state = {
    quizStatus: [],
  };

  public componentWillMount() {
    const quizStatus = JSON.parse(
      window.localStorage.getItem(`${window.location.host}-quiz`) || "{}"
    );
    if (quizStatus) {
      this.setState({ quizStatus });
    }
  }

  public getCardParams(id: number) {
    const status: IQuizStatus = this.state.quizStatus[id];

    let props: any = {
      id: id.toString(),
      to: `/quiz/${id}`,
      success: true,
    };
    if (status) {
      props = {
        ...props,
        done: status.currentQuestion >= status.totalQuestion,
        score: `${status.userScore}/${status.totalScore}`,
        pursue: true,
        onRetry: () => {
          const quizStatus = this.state.quizStatus;
          delete quizStatus[id];
          window.localStorage.setItem(
            `${window.location.host}-quiz`,
            JSON.stringify(quizStatus)
          );
        },
      };
    }
    return props;
  }

  public render() {
    return (
      <CardContainer>
        <Card image={quiz3} title="Connecté" {...this.getCardParams(11119)} />
      </CardContainer>
    );
  }
}

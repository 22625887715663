import React from 'react'
import { RecapCard } from '@dsk-lib/reservation'
import { Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setReservationCurrentStep } from '../../store/reservation/actions'
import { stepList } from './index'
import { RouteComponentProps, Redirect } from 'react-router'
import { IAppState } from '../../store'
import { ReservationStep } from './reservation.constant'
import moment from 'moment'

export default ({history}: RouteComponentProps) => {
  /** Store */
  const dispatch = useDispatch()
  const {site, session} = useSelector((state: IAppState) => state.reservation)

  /** Constant */
  const nextStep = ReservationStep.Account

  /**
   * Handle step change
   */
  const handleNextStep = () => {
    dispatch(setReservationCurrentStep(nextStep))
    history.push(stepList[nextStep].slug)
  }

  if (!site || !session) {
    return <Redirect to="/reservation/recherche"/>
  }

  return (
    <Box style={{maxWidth: 788, width: '100%'}}>
      <RecapCard
        title="Récapitulatif de votre réservation"
        echo="Récapitulatif"
        name={site.name}
        address={site.address}
        date={moment(session.openingTime).format('dddd DD MMMM YYYY à HH:mm')}
        onNextStep={handleNextStep}
      />
    </Box>
  )
};

// Polyfill
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// Core
import React from "react";
import ReactDom from "react-dom";

// Router
import { BrowserRouter, Route, Switch } from "react-router-dom";

// dsk lib
import { UserAuthenticationProvider } from "@dsk-lib/user";

// Component
import App from "./App";
import AppManager from "./components/AppManager";

// Constant
import { keycloakConfig, keycloakInitOptions } from "./config/keyclock";

// Material ui
import { ThemeProvider } from "@material-ui/styles";
import theme from "./Theme";

// Store
import { Provider } from "react-redux";
import store from "./store";

// Pages
import Reservation from "./pages/Reservation";
import ProfilePage from "./pages/ProfilePage";

// Utils
import moment from "moment";
import "moment/locale/fr";

// Style
import "minireset.css/minireset.css";
import { createGlobalStyle } from "styled-components";

import { ROUTE_PROFILE } from "./config/route.constant";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    font-family: "roboto", sans-serif;
  }
`;
moment.locale("fr");

ReactDom.render(
  <Provider store={store}>
    <UserAuthenticationProvider
      keycloakConfig={keycloakConfig}
      keycloakInitOptions={keycloakInitOptions}
    >
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Route exact={false} path="/" component={AppManager} />
          <Switch>
            <Route exact={true} path={ROUTE_PROFILE} component={ProfilePage} />
            <Route exact={false} path="/" component={App} />
            <Route exact={true} path="/reservation" component={Reservation} />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </UserAuthenticationProvider>
  </Provider>,
  document.querySelector("#root")
);
